import styles from './LoginForm.module.css';
import { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux';
import { ForgetPass, LoginUser, PopupState, SinginUser, VerifyEmail, resetPassword } from '../../../api/userLogInReducer';
import Loading from '../../Component/Loading/Loading';
import { checkDateRange } from '../Profile/ProfileSideBar';
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import model from "../../Component/Model/Model.module.css"
function Auth() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const l = queryParams.get('login');
	const s = queryParams.get('sign-up');
	const f = queryParams.get('forgot-password');
	const v = queryParams.get('verify');
	const e = queryParams.get('verify-email');
	const r = queryParams.get('reset-password');
	const t = queryParams.get('token');
	const p = queryParams.get('p');
	const [waiting, setWaiting] = useState(e || false)
	const dispatch = useDispatch()
	const [loginForm, setLoginForm] = useState("sinup")
	const { user } = useSelector((state) => state.userLog)
	const navigate = useNavigate()
	const formClass = loginForm === 'login' ? styles.login : loginForm === 'forgotPassword' ? styles.forgotPassword : loginForm === 'verify' ? styles.verify : styles.signup;
	useEffect(() => {
		if (s === 'true') {
			setLoginForm('singUp');
		} else if (l === 'true') {
			setLoginForm('login');
		} else if (f === 'true') {
			setLoginForm('forgotPassword');
		} else if (v === 'true') {
			setLoginForm('verify');
		} else if (e === 'true') {
			dispatch(VerifyEmail({ token: t })).then((e) => {
				setWaiting(false)
				if (e?.payload?.success) {
					dispatch(PopupState({ status: "Success", message: "Successfully Registered" }))
					localStorage.setItem("token", e.payload.token)
				} else {
					dispatch(PopupState({ status: "Error", message: "Your link is expired or invalid please register again " }))
				}
			})
		} else if (r === 'true') {
			dispatch(resetPassword({ token: t, password: p })).then((e) => {
				setWaiting(false)
				if (e?.payload?.success) {
					dispatch(PopupState({ status: "Success", message: "Successfully Changed your password Now you can Login with new password" }))
					localStorage.setItem("token", e.payload.token)
				} else {
					dispatch(PopupState({ status: "Error", message: "Your link is expired please Resend your mail" }))
				}
			})
		}
	}, [l, s, f, v, e, r]);
	if (user?._id) {
		return (
			<Navigate to={`/search`} />
		)
	}
	return (
		<>
			<div className={`${styles.mostOuter} `} >
				{
					waiting && <>
						<div className={model.model}>
							<div className={model.messageBox}>
								<div className={model.icon} style={{ backgroundColor: "gray" }} >
									<Loading />
								</div>
								<h2>Waiting</h2>
								<p>Wait we are check your profile</p>
							</div>
						</div>
					</>
				}
				<div className={styles.top}>
					<div className={styles.backButton} onClick={() => navigate("/")}>
						<FaArrowLeft size={25} />
						<span >Back to Home</span>
					</div>
					<div className={styles.logo}>
						<img src="/icon.png" alt="" onClick={() => navigate("/")} />
					</div>
				</div>
				<div className={styles.text}>
					<div>
						<span onClick={() => navigate("/Privacy-policy")}>Privacy Policy</span> .
						<span onClick={() => navigate("/term&condition")}>Term & Condition</span> .
						<span onClick={() => navigate("/contact-us")}>Contact us</span>
					</div>
					<p>Developed by <a href="https://igeeksquadbay.com/" target='_blank'>iGeek Squad Bay Pvt Ltd</a> </p>
				</div>
				<div className={`${styles.wrapper} ${formClass}`} id="wrapper">
					<div>
						<SingPage setLoginForm={setLoginForm} />
						<LoginPage setLoginForm={setLoginForm} />
						<ForgotPassword setLoginForm={setLoginForm} />
						<VerifyPage setLoginForm={setLoginForm} />
					</div>
				</div>
			</div>
		</>
	);
}

const VerifyPage = ({ setLoginForm }) => {
	const [steps, setSteps] = useState(0)
	return (
		<>
			<div className={`${styles.form_box} ${styles.verify}`} >
				<form action="#">
					<h2>Verify</h2>
				</form>
			</div>
		</>
	)
}
const SingPage = ({ setLoginForm }) => {
	const [steps, setSteps] = useState(0)
	const [user, setUser] = useState({ dob: "", bt: "", bp: "", name: "", email: "", password: "" })
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [term, setTerm] = useState(false)
	const navigate = useNavigate()
	const InputRender = () => {
		switch (steps) {
			case 0:
				return (<>
					<div className={styles.input_box}>
						<input type="date" placeholder='dd-mm-yyyy' max="3000-12-31" id="dob" onChange={(e) => setUser({ ...user, "dob": e.target.value })} />
						<label htmlFor="dob">Date of Birth*</label>
					</div>
					<button onClick={(e) => {
						e.preventDefault()
						const d = new Date(user.dob)
						const c = new Date()
						const p = new Date(1 - 1 - 1950)
						if (c > d && d > p) {
							setSteps(steps + 1)
						}
						else {
							dispatch(PopupState({ status: "Error", message: "Enter Your Valid Date Of birth, You can enter date between 01-01-1970 to current date" }))
						}
					}} className={styles.btn}>Next</button>
					<div className={styles.login_register}>
						<p>Already have an account? <span style={{ cursor: "pointer" }} onClick={() => {
							setLoginForm('login')
							navigate("/auth?login=true")
						}} className="login-link" id="login-link">Login now</span></p>
					</div>
				</>
				)
				break;
			case 1:
				return (
					<>
						<div className={styles.input_box}>
							<input type="time" id="bt" onChange={(e) => setUser({ ...user, "bt": e.target.value })} />
							<label htmlFor="bt">Birth Time</label>
						</div>
						<div className={styles.remember_password}>
							<label htmlFor=""> </label>
							<span style={{ cursor: "pointer" }} onClick={() => setSteps(steps + 1)} >Don't remember? Skip</span>
						</div>
						<button onClick={(e) => {
							e.preventDefault()
							setSteps(steps + 1)
						}} className={styles.btn}>Next</button>
						<div className={styles.login_register}>
							<p style={{ cursor: "pointer" }} onClick={() => setSteps(steps - 1)} >Previous Step</p>
						</div>
					</>
				)
				break;
			case 2:
				return (
					<>
						<div className={styles.input_box}>
							<span className={styles.icon}><ion-icon name="location"></ion-icon></span>
							<input type="text" id="bp" onChange={(e) => setUser({ ...user, "bp": e.target.value })} />
							<label htmlFor="bp">Birth Place</label>
						</div>
						<button onClick={(e) => {
							e.preventDefault()
							setSteps(steps + 1)
						}} className={styles.btn}>Next</button>
						<div className={styles.login_register}>
							<p style={{ cursor: "pointer" }} onClick={() => setSteps(steps - 1)} >Previous Step</p>
						</div>
					</>
				)
				break;
			case 3:
				return (
					<>
						<div className={styles.input_box}>
							<p></p>
							<span className={styles.icon}><ion-icon name="person"></ion-icon></span>
							<input type="text" id="name" onChange={(e) => setUser({ ...user, "name": e.target.value })} />
							<label htmlFor="name">Username*</label>
						</div>
						<button onClick={(e) => {
							e.preventDefault()
							if (user.name) {
								setSteps(steps + 1)
							}
							else {
								dispatch(PopupState({ status: "Error", message: "Enter Your Valid Name" }))
							}
						}} className={styles.btn}>Next</button>
						<div className={styles.login_register}>
							<p style={{ cursor: "pointer" }} onClick={() => setSteps(steps - 1)} >Previous Step</p>
						</div>
					</>
				)
				break;
			case 4:
				return (
					<>
						<div className={styles.input_box}>
							<span className={styles.icon}><ion-icon name="mail"></ion-icon></span>
							<input type="email" id="email" onChange={(e) => setUser({ ...user, "email": e.target.value })} />
							<label htmlFor="email">Email*</label>
						</div>
						<button onClick={(e) => {
							e.preventDefault()
							let emailRegex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/
							if (!emailRegex.test(user.email)) {
								dispatch(PopupState({ status: "Error", message: "Enter Your Valid Email" }))
							}
							else {
								setSteps(steps + 1)
							}
						}} className={styles.btn}>Next</button>
						<div className={styles.login_register}>
							<p style={{ cursor: "pointer" }} onClick={() => setSteps(steps - 1)} >Previous Step</p>
						</div>
					</>
				)
				break;
			case 5:
				return (
					<>
						<div className={styles.input_box}>
							<span className={styles.icon}><ion-icon name="lock-closed"></ion-icon></span>
							<input type="password" id="password" onChange={(e) => setUser({ ...user, "password": e.target.value })} />
							<label htmlFor="password">Password*</label>
						</div>
						<div className={styles.remember_password}>
							<label htmlFor=""><input type="checkbox" checked={term} onChange={() => setTerm(!term)} />Agree to the term & condition </label>
						</div>
						<button onClick={(e) => {
							e.preventDefault()
							if (term) {
								setLoading(true)
								const zodiac = checkDateRange(user.dob)
								dispatch(SinginUser({ ...user, zodiac })).then((e) => {
									setLoading(false)
									if (e.payload?.success) {
										setSteps(steps + 1)
									} else {
										dispatch(PopupState({ status: "Error", message: e?.payload?.message || "Internal server error" }))
									}
								})
							} else {
								dispatch(PopupState({
									status: "Error", message: "You need to Check term & conditions "
								}))
							}
						}} className={styles.btn}>{loading ? <Loading /> : "Submit"}</button>
						<div className={styles.login_register}>
							<p style={{ cursor: "pointer" }} onClick={() => setSteps(steps - 1)} >Previous Step</p>
						</div>
					</>
				)
				break;
			case 6:
				return (
					<>
						<div className={styles.login_register}>
							<p>the verification email has been sent to your email id please cheack your email and click on button </p>
						</div>
						<button style={{ marginTop: "10px" }} onClick={(e) => {
							e.preventDefault()
							navigate("/")
						}} className={styles.btn}>check now</button>
					</>
				)
				break;
		}
	}
	return (
		<>
			<div className={`${styles.form_box} ${styles.register}`} >
				<form action="#">
					<h2>Register</h2>
					{
						InputRender()
					}
				</form>
			</div>
		</>
	)
}
const ForgotPassword = ({ setLoginForm }) => {
	const dispatch = useDispatch()
	const [user, setUser] = useState({ email: "", password: "", nPassword: "" })
	const [rememberMe, setRememberMe] = useState(false)
	const [loading, setLoading] = useState(false)
	return (
		<>
			<div className={`${styles.form_box} ${styles.forgotPassword}`}>
				<h2>Forgot password</h2>
				<form action="#">
					<div className={styles.input_box}>
						<span className={styles.icon}><ion-icon name="mail"></ion-icon></span>
						<input autoComplete="false" autoCorrect='false' autoSave='false' type="email" id='email' onChange={(e) => setUser({ ...user, "email": e.target.value })} />
						<label htmlFor="email">Email</label>
					</div>
					<div className={styles.input_box}>
						<span className={styles.icon}><ion-icon name="lock-closed"></ion-icon></span>
						<input autoComplete="false" autoCorrect='false' autoSave='false' type="password" id='password' onChange={(e) => setUser({ ...user, "password": e.target.value })} />
						<label htmlFor="password">New Password</label>
					</div>
					<div className={styles.input_box}>
						<span className={styles.icon}><ion-icon name="lock-closed"></ion-icon></span>
						<input autoComplete="false" autoCorrect='false' autoSave='false' type="password" id='nPassword' onChange={(e) => setUser({ ...user, "nPassword": e.target.value })} />
						<label htmlFor="nPassword">Retype Password</label>
					</div>
					<div className={styles.remember_password}>
						<label htmlFor=""> </label>
						<span style={{ cursor: "pointer" }} onClick={() => setLoginForm("login")} >Back to Login</span>
					</div>
					<button onClick={(e) => {
						e.preventDefault()
						setLoading(true)
						if (user.nPassword === user.password) {
							dispatch(ForgetPass({ c: user.email, p: user.password })).then((e) => {
								setLoading(false)
								e.payload.success ? dispatch(PopupState({ status: "Success", message: `Reset password mail send successfully please check your mail` })) : dispatch(PopupState({ status: "Error", message: e.payload.message }))
							})
						}
						else {
							dispatch(PopupState({ status: "Error", message: `Your password and retype ` }))
						}
					}} className={styles.btn}>{loading ? <Loading /> : "Send Verification"}</button>
				</form>
			</div >
		</>
	)
}
const LoginPage = ({ setLoginForm }) => {
	const dispatch = useDispatch()
	const [user, setUser] = useState({ email: "", password: "" })
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	return (
		<>
			<div className={`${styles.form_box} ${styles.login}`}>
				<h2>Login</h2>
				<form action="#">
					<div className={styles.input_box}>
						<span className={styles.icon}><ion-icon name="mail"></ion-icon></span>
						<input autoComplete="false" autoCorrect='false' autoSave='false' type="email" id='email' onChange={(e) => setUser({ ...user, "email": e.target.value })} />
						<label htmlFor="email">Email</label>
					</div>
					<div className={styles.input_box}>
						<span className={styles.icon}><ion-icon name="lock-closed"></ion-icon></span>
						<input autoComplete="false" autoCorrect='false' autoSave='false' type="password" id='password' onChange={(e) => setUser({ ...user, "password": e.target.value })} />
						<label htmlFor="password">Password</label>
					</div>
					<div className={styles.remember_password}>
						<label htmlFor=""> <input type="checkbox" />Remember me </label>
						<span style={{ cursor: "pointer" }} onClick={() => {
							setLoginForm("forgotPassword")
							navigate("/auth?forgot-password=true")
						}} >Forgot Password</span>
					</div>
					<button onClick={(e) => {
						e.preventDefault()
						setLoading(true)
						dispatch(LoginUser({ c: user.email, p: user.password })).then((e) => {
							setLoading(false)
							if (e?.payload?.success) {
								dispatch(PopupState({ status: "Success", message: "Login Successfully" }))
								localStorage.setItem("token", e.payload.token)
							} else {
								dispatch(PopupState({ status: "Error", message: e.payload.message }))
							}
						})
					}} className={styles.btn}>{loading ? <Loading /> : "Login"}</button>
					<div className={styles.login_register}>
						<p>Don't have an account? <span style={{ cursor: "pointer" }} onClick={() => {
							setLoginForm("singUp")
							navigate("/auth?sign-up=true")
						}} className={styles.register_link} id="register-link">Register now</span></p>
					</div>
				</form>
			</div >
		</>
	)
}
export default Auth;