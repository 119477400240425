import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import "./Components/Pages/HomePage/home.css"
import './index.css';
import './s.css';
import logo from "./images/logo.png"
import { BrowserRouter, Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Pages/HomePage/Home';
import Singin, { SignInFrom } from './Components/Pages/LoginForms/Singin';
import { NavBar, RequestForAstrologer } from './Components/Component/All';
import SearchPage from './Components/Pages/SearchPage';
import ForgotPass, { ForgotForm } from './Components/Pages/LoginForms/ForgotPass';
import ChatSideBar from './Components/Pages/Chat/ChatSideBar';
import BlogPage from './Components/Pages/BlogPage';
import Blog from './Components/Pages/Blog';
import Verification from './Components/Pages/LoginForms/Verification';
import io from 'socket.io-client';
import ProfileSideBar, { ProfileID, AstrologerForm, AstrologerBlog, Wallet } from './Components/Pages/Profile/ProfileSideBar';
import { ClosePopupState, GetAstrologers, Recharge, TokenLogin, offlineChat } from './api/userLogInReducer';
import { MessageHandler } from './api/chatReducer';
import store from './store';
import RequestsPage from './Components/Pages/RequestsPage';
import CallModel from './Components/Pages/CallModel';
import ChatPage from './Components/Pages/ChatPage';
import android from "./images/download/GooglePlay.png"
import AstrologerPage from './Components/Pages/AstrologerPage';
import EditPage from './Components/Pages/EditPage';
import { FaXmark } from 'react-icons/fa6';
import { ClientChat, RemoveRequest } from './api/ChatRequestReducer';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import OffChats from './Components/Pages/OffChats';
import Rechargeadd from './Components/Pages/Rechargeadd';
import Auth from './Components/Pages/LoginForms/Auth';
import Model from './Components/Component/Model/Model';
import Landing from './Components/Pages/Landing/Landing';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

// const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });


const ENDPOINT = "https://admin.unzziptruth.com"

const ClintRoutes = () => {
  const dispatch = useDispatch()
  const socketRef = useRef()
  const { user, model } = useSelector((state) => state.userLog)
  useEffect(() => {
    const tokenHandler = async () => {
      const token = await localStorage.getItem("token")
      console.log(token)
      if (token) {
        console.log(token)
        dispatch(TokenLogin({ token })).then((e) => console.log(e.payload))
      }
    }
    tokenHandler()
  }, [])
  useEffect(() => {
    if (user?._id) {
      socketRef.current = io(ENDPOINT)
      socketRef.current.emit("setup", user._id)
      console.log(socketRef.current)
    }
  }, [user])
  const [callFromAstro, setCallFromAstro] = useState(false)
  const [astro, setAstro] = useState({})
  const [client, setClient] = useState({})

  const navigate = useNavigate()
  useEffect(() => {

    if (user?._id) {
      socketRef.current.on("message received", (newMessageReceived) => {
        dispatch(MessageHandler(newMessageReceived))
      })
      socketRef.current.on("astroReject", (newMessageReceived) => {
        dispatch(RemoveRequest(newMessageReceived.astro))
      })
      socketRef.current.on("ChatPage", ({ path, astro }) => {
        setAstro({ ...astro })
        navigate(`/ChatPage/${path}`)
      })

    }
  })
  const a = useParams()
  const { token } = useParams()
  const value = a["*"]
  return (
    <>
      <PayPalScriptProvider options={{ "client-id": "AS3ieuQvAhlw1neiJ5cF_tt-IUdGoMyE5n05bOnIUvWkZAv-RobWmqTbKbqBNSe91LJ_NA3CcZVw7DRb" }}>
        <Model />
        <Routes>

          <Route exact path="/" element={<Home socketRef={socketRef} />} />
          <Route exact path="/ChatPage/:id" element={<ChatPage socketRef={socketRef} setAstro={setAstro} astro={astro} />} />
          <Route exact path="/Privacy-policy" element={<Pp />} />
          <Route exact path="/term&condition" element={<Tc />} />
          <Route exact path="/mobile/payment" element={<Rechargeadd />} />
          <Route exact path="/chat/*" element={<ChatSideBar />} />
          <Route exact path="/profile/:id/*" element={<ProfileSideBar >
            <Routes>
              <Route exact path='/' element={<ProfileID />} />
              <Route exact path='/wallet' element={<Wallet />} />
            </Routes>
          </ProfileSideBar>} />
          <Route exact path='/offChats' element={<OffChats />} />
          <Route exact path="/auth" element={<Auth />} />
          <Route exact path="/horoscopy" element={<Blog />} />
          <Route exact path="/contact-us" element={<Ad />} />
          <Route exact path="/astro-form" element={<AstrologerForm />} />
          <Route exact path="/astrologer/:id" element={<AstrologerPage socketRef={socketRef} />} />
          <Route exact path="/search" element={<SearchPage socketRef={socketRef} />} />
          <Route exact path="/home/landing" element={<Landing />} />
        </Routes>
        <RequestForAstrologer socketRef={socketRef} />

        {/* */}
      </PayPalScriptProvider>

    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        <Route exact path="/*" element={<ClintRoutes />} />
      </Routes>
    </Provider>
  </BrowserRouter>
);
export const Footer = () => {
  return (
    <>
      <footer style={{ backgroundColor: "var(--cta-white)" }}>
        <div className='container footer' style={{ display: "flex", gap: "20px", justifyContent: "space-evenly", padding: "50px 10px", flexWrap: "wrap" }}>
          <div style={{ display: "flex", gap: "0px", flexDirection: "column", alignItems: "center", alignSelf: "center" }}>
            <div style={{ height: "80px", }}>
              <img src={logo} style={{ height: "100%", width: "100%" }} alt="" />
            </div>
            <Link to="/"> <img style={{ height: "80px" }} src={android} alt="" /></Link>
            <div style={{ display: "flex", gap: "10px" }}><a href="https://www.instagram.com/unzziptruth" target='_blank'><FaInstagram size={30} /></a><a href="https://www.facebook.com/UnzzipTruthOfficial" target='_blank'><FaFacebook size={30} /></a><a href="https://www.linkedin.com/company/unzzip-truth/" target='_blank'><FaLinkedin size={30} /></a></div>
          </div>
          <div style={{ display: "flex", gap: "0px", flexDirection: "column", alignItems: "flex-start" }}>
            <p style={{ color: "var(--dark)", fontSize: "20px", fontWeight: "600", marginBottom: "20px" }}>Usefull Links</p>
            <Link to="/Privacy-policy">Privacy policy</Link>
            <Link to="/term&condition">Term & Conditions</Link>
            <Link to="/contact-us">Contact Us</Link>
            <Link to="/astro-form">Work with Astrologers</Link>
            <Link to="/">About Us</Link>

          </div> <div style={{ display: "flex", gap: "0px", flexDirection: "column", alignItems: "flex-start" }}>
            <p style={{ color: "var(--dark)", fontSize: "20px", fontWeight: "600", marginBottom: "20px" }}>Online Advice </p>
            <Link to="/">Chat with Astrologers</Link>
            <Link to="/">Astrologers</Link>
            <Link to="/">Tarot readers</Link>
            <Link to="/">Numerologists</Link>
            <Link to="/">Vastu expets </Link>
            <Link to="/">Fengshui experts </Link>
          </div>

        </div>
        <div style={{ height: "70px", background: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <p style={{ color: "white", }}>Developed by <a href="https://igeeksquadbay.com/" target='_blank' style={{ color: "#ff4949" }}>iGeek Squad Bay Pvt Ltd</a></p>
        </div>
      </footer>
    </>
  )
}
export const PaymentModel = ({ prise, setShow }) => {
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const { user } = useSelector((state) => state.userLog)
  const dispatch = useDispatch()
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          user: user._id,
          amount: {
            currency_code: "USD",
            value: prise,
          },
        },
      ],
    }).then((order_id) => {
      setOrderID(order_id)
      return order_id;
    });
  };
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      if (details.status === "COMPLETED") {
        dispatch(Recharge({ userID: user._id, amount: details.purchase_units[0].amount.value })).then((e) => console.log(e.payload))
      }
      setSuccess(true);
    });
  };
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };
  return (
    <>

      <div style={{ position: "absolute", top: "0", right: "0px", zIndex: "999", backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
        <div className='paymentModel' style={{ position: "relative", maxHeight: "50%", overflowY: "scroll", maxWidth: "440px", width: "95%", backgroundColor: "#f2f2f2", padding: "50px 20px", borderRadius: "10px" }}>
          <FaXmark onClick={() => setShow(false)} style={{ position: "absolute", top: "20px", right: "20px" }} />
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
          />
        </div>
      </div>

    </>
  )
}
const Pp = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // Optionally, you can also scroll to the top when the component unmounts
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <>
      <NavBar />
      <div style={{  backgroundColor: "var(--white)" }}>
        <div className='container' style={{}}>
          <h2 style={{ textAlign: "center", fontSize: "30px" }}>Privacy Policy
          </h2>
          <p style={{ fontSize: "20px", padding: "20px 10px" }}>
            www.unzziptruth.com (“we”, “Unzzip Truth”, “Unzzip Truth” (web and
            application) hereinafter referred as “website”) is committed to
            protect the privacy of the users of the website (including astrologers
            and buyers/customers whether registered or not registered). Please
            read this privacy policy carefully to understand how the website is
            going to use your information supplied by you to the Website.
            <br /><br />
            This Privacy Policy is published in accordance with Rule 3(1) of the
            Information Technology (Intermediaries Guidelines) Rules, 2011 and
            Information Technology (Reasonable Security Practices and
            Procedures and Sensitive Personal Data or Information) Rules, 2011
            which requires publishing of the Privacy policy for collection, use,
            storage and transfer of sensitive personal data or information.
            <br /><br />
            <strong>USER’S CONSENT</strong>
            <br /><br />
            This Privacy Policy, which may be updated/amended from time to
            time, deals with the information collected from its users in the form of
            personal identification, contact details, birth details and any forecast
            made using the supplied information and how such information is
            further used for the purposes of the Website. By accessing the website
            and using it, you indicate that you understand the terms and expressly
            consent to the privacy policy of this website. If you do not agree with
            the terms of this privacy policy, please do not use this website.
            <br /><br />
            Your continued use of this website shall confirm that you have
            provided your unconditional consent and confirm to the terms of this
            privacy policy as regards collecting, maintaining, using, processing
            and disclosing your personal and other information in accordance
            with this Privacy Policy.
            <br /><br />
            This Privacy Policy is to be read alongwith the respective Terms of
            Use or other terms and conditions as provided on the Website.

            <br /><br />
            <strong>Collection of Personal Information</strong>
            <br /><br />
            Creating a user profile with Unzzip Truth involves providing specific
            information. The mandatory details include your phone number for
            OTP (One-Time Password) verification, which is necessary to ensure
            the security and validity of the registration process. Additionally, you
            are required to provide your first name, last name, and date of birth
            (DOB) are optional. The date of birth can be considered an optional
            detail, which means it's not mandatory to provide it during the
            registration process.
            <br /><br />
            <strong>PURPOSE AND USE OF DATA/INFORMATION COLLECTION
            </strong>
            <br /><br />
            By collecting this information, Unzzip Truth aims to create a
            personalized user profile that can cater to your specific needs and
            preferences. However, if you choose not to provide your date of birth,
            it won't hinder the registration process, and you can still access the
            services offered by Unzzip Truth with your verified phone number.
            <br /><br />
            <strong>Data Deletion</strong>
            <br /><br />
            Delete Profile: If you wish to delete your entire Unzzip Truth profile,
            including personal information associated with it, you might find an
            option to "Delete your account" Click on this setting option in side
            menu and follow the instructions and complete the account deletion
            process. You can reach us for deletion of your data by visiting <a href="/contact-us">Contact-us</a> page
            <br /><br />
            <strong>Camera and gallery permission</strong>
            <br /><br />
            In our app we have given feature to user to change profile photo from camera or gallery so we are asking grant us this permission
            <br /><br />
            <strong>Voice Recording and Microphone Permission</strong>
            <br /><br />
            In our app, we've implemented a unique and convenient way for you
            to ask questions and interact with us in your chat — by recording your
            voice as audio. Instead of typing out your queries, you also have an
            option to simply speak into your device's microphone, record the
            voice as audio, and then send it on chat. This audio interaction feature
            is designed to make using our app more intuitive and in tune with
            natural communication.
            <br /><br />
            To enable you to record your questions and thoughts in your own
            voice as audio, we need to request access to your device's
            microphone. When you grant us this permission, it allows our app to
            capture the sound of your voice as audio and convert it into digital
            data that we can process
            <br /><br />
            <strong>COMMITMENT</strong>
            <br /><br />
            The Website intends to protect the privacy of all kinds of users visiting
            the platform irrespective whether the user is a registered user or
            merely a visitor. It is recommended to every user to understand what
            types of personally identifiable information is collected. The Website
            employs the personally identifiable information for certain predictions
            however it is guaranteed that no direct or indirect use of such
            information which is revealed in the prediction for a member will be
            done except for the explicit purpose of communicating the horoscope
            charts and predictions to the member itself disclosing such
            information. It is further clarified that the Website does not in any
            manner deal in selling or renting the information supplied to the
            Website.
            <br /><br />
            The Website does not commit to treat or provide solutions for users
            with weak mental health which is inclusive of any user who have
            thoughts related to committing suicide, self-destruction etc. Such
            users are advised to stop the use of the present website with
            immediate effect and any continued use of the website by such
            person would be considered solely at the user’s risk and the Website
            shall have no liability for any untoward event in such scenario. The
            Website declares that the information provided by such kind of user
            can be shared, if required, with law enforcement authorities. Such
            information is not protected from any kind of non-disclosure or
            confidential agreements either with the Website or with any thirdparty involved herein.
            <br /><br />
            The Website does not commit in any manner whatsoever for the
            accuracy of the predictions made by the astrologers to any user. The
            Website does not take any guarantee/responsibility/liability
            regarding the reliability or reality of the gems and other related items
            represented and sold on the website. It is further declared by the
            Website that no warranty on such service is provided by the Website
            in any manner.
            <br /><br />
            <strong>INFORMATION COLLECTED BY WEBSITE
            </strong>
            <br /><br />
            PERSONAL IDENTIFIABLE INFORMATION: The information qualifies as
            personal in nature when the information collected identifies a specific
            end user. Such information would be collected by the website during
            the following actions:-
            <br /><br />
            <ul>
              <li>Creating an account / Registration data: While accessing the
                Website, the User of the Website may be required to create an
                account. The personal information which may be sought while
                creating an account shall include, but not limited to the Full
                name, Address, Telephone Number, Email-address, Date of Birth,
                Gender, Location, Photograph, any other items of ‘sensitive
                personal data or information” as such term is defined under the
                Information Technology (Reasonable Security Practices And
                Procedures And Sensitive Personal Data Of Information) Rules,
                2011 enacted under the Information Technology Act, 2000, and
                any other detail required on the website during registration.
                It is hereby informed to all the Users that the e-mail address or
                phone number together with a password or OTP is used for the
                purpose of securing User’s profile and for effective
                implementation of the personalized E-mail and SMS Services
                provided by the Website to the User. In the event that no
                registration is made by the User, the Website may not be able to
                provide any services due to non-availability of the personal
                identifiable information of the User.</li><br />
              <li>Booking a paid service: While booking a service through Order
                Form, the personal information which may be sought would
                include, but not limited to the information as mentioned in
                Column 1(a), financial information inclusive of bank account
                information, credit card or debit card details or other payment
                instrument details through a secure third party gateway, IP
                (Internet protocol) Address and any other information that a
                User may provide during booking a paid service on the Website.
                Such information is kept highly confidential.</li><br />
              <li>Log Files, IP Address and Cookies: The website collects
                information that is stored by your browser on your computer’s
                hard drive i.e. through cookies. It further automatically log
                generic information about the user’s computer connection to
                the Internet i.e. Session Data. The website may store temporary
                or permanent ‘cookies’ on the user’s computer. Cookies would
                allow the web server to recognize the user computer each time
                the user returns to the website including the time and date of
                the visit, viewing of page, length of time, verify registration or
                password information etc. Such cookies are usually only read by
                the server placed and the user may choose to block these
                cookies on their computers. Please note that if the cookies are
                turned off, the user may be prevented from using certain
                features of the website. The website uses the cookies to
                personalize the user’s experience on the website and to display
                an advertisement according to the user’s preferences.
                Some of the services provided by the Website may direct the
                User to platform of third parties. Any Information provided by the
                User on such platforms may be dealt by them in the manner
                provided by the privacy policy formulated by such third-party
                platforms. The Website in this regard fully disclaims any
                liability(ies) or claim(s) which may arise by use/misuse of such
                information shared by the User, to any third party or any party
                not known to the Website. The website would not liable for the
                mis-use of such information shared by the User or by any third
                party. <br /><br />
                We also collect details including but not limited to User
                feedback, comments, etc. that may be
                disclosed/informed/mentioned on any article/blog or
                groups/forums or other pages which the User may have access
                to while visiting the Website. For such information which is in
                public domain and accessible to all the Users and visitors of the
                Website, the User is advised to exercise its discretion before
                disclosing it as this information is susceptible to misuse</li><br />
              <li>
                Miscellaneous Activities: The Website may collect any other
                information which may be mandatory to be disclosed and
                further may receive any other information via email or other
                method inclusive of contract with regard to specific services
                availed from the Website or any products bought from the
                Website, such information may not be made part of the UserMember’s Profile but shall be used only for addressing the
                specific need or concern of the User
              </li>
            </ul>
            <br /><br />
            NON - PERSONAL IDENTIFIABLE INFORMATION: The information qualifies
            as non-personal in nature when the information collected does not
            identify a specific end user. Such information is collected when the
            user visits the Website, cookies, etc. and would include but not limited
            to the following:
            <br /><br />
            <ul>
              <li>URL (Uniform Resource Locator) of the previous website visited
                by the User before visiting this website or the URL of the website
                the User visits after visiting this Website.</li><br />
              <li>Internet service provider / IP Address / Telecom service provider.</li><br />
              <li>Type of Browser used for accessing the website.</li><br />
              <li>Geographical Location</li>

            </ul>
            <br /><br />
            Such non-personal identifiable information is used by the Website for
            the purposes including but not limited to troubleshoot connection
            problems, administer the website, analyze trends, gather
            demographic information, frequency of visits to the website, average
            length of visits, pages viewed during a visit, compliance with
            applicable law, and cooperate with law enforcement activities, etc.
            <br /><br />
            The information is used for improving the site content and
            performance and the website may share this information with Third
            Party Service Providers and Third Party Advertisers to measure the
            overall effectiveness of the website’s online advertising, content,
            programming and for other bonafide purpose as required.

            <br /><br />
            THE USER HEREBY REPRESENT AND CONFIRMS THAT THE INFORMATION
            PROVIDED TO THE WEBSITE IS AUTHENTIC, CORRECT, CURRENT AND
            UPDATED. THE WEBSITE AND ITS ENTITES SHALL NOT BE RESPONSIBLE FOR
            THE AUTHENTICITY OF THE INFORMATION THAT THE USER MAY PROVIDE.
            THE USER SHALL BE PERSONALLY LIABLE AND INDEMNIFY THE WEBSITE FOR
            THE BREACH OF ANY PROVISION.
            <br /><br />
            SECURITY MEASURES: The security of the personal information supplied
            by the User is very important to the Website and the website for the
            purpose of securing the information takes various measures inclusive
            of taking reasonable steps such as physical and electronic security
            measures to guard against the unauthorized access to the
            information. The personal information of a user is collected on a
            secured server. The payment details are entered on the Payment
            Gateway’s or Bank’s page on a secured SSL. The data is transferred
            between Bank’s page and payment’s gateways in an encrypted
            manner. However please note that no data transmission can be guaranteed to be completely secure. Hence the user is advised to take
            precaution and care against any sharing of the details submitted on
            the website included the log-in details as generated after registration.
            The website is not responsible for the security or confidentiality of
            communications the user may send through the internet using email
            messages, etc.
            <br /><br />
            USAGE OF THE INFORMATION: The information collected by the Website
            may be used for any purpose as may be permissible under the
            applicable law and shall include but not limited to the following: -
            <br /><br />
            <ul>
              <li>
                For providing a personalised browsing experience. While
                guaranteeing the anonymity of the user, the personal
                information collected in Clause “Personal Identifiable
                Information” may be used for research purposes, for improving
                the marketing and promotional efforts, to analyse usage,
                improve the content of the Website, product offering and for
                customising the Website’s layout for suiting the needs of its
                Users.
              </li><br />
              <li>With IP tracking details and Cookies data, the Website will use it
                only for facilitating the usage of the website and provide
                personalised experience and any information which is sensitive
                in nature will not be provided to any third party without the
                consent of the User.</li><br />
              <li>All information (and copies thereof) collected by Website,
                including without limitation Personal Information, User Data, and
                other information related to your access and use of the services
                offered by Website, may be retained by Website for such period
                as necessary, including but not limited to, for purposes such as
                compliance with statutory or legal obligations, tax laws and
                potential evidentiary purposes and for other reasonable
                purposes such as to implement, administer, and manage your
                access and use of our services, or resolution of any disputes.
              </li><br />
              <li>To ensure a seamless experience at the Website for you and to
                ensure your maximum benefit and comfort, the Website may use
                the data collected through cookies, log file, device identifiers,
                location data and clear gifs information to: (a) remember
                information so that you will not have to re-enter it during your
                visit or the next time you visit the site; (b) provide custom, personalized content and information, including advertising; (c)
                provide and monitor the effectiveness of Services offered by
                Website; (d) monitor aggregate metrics such as total number of
                visitors, traffic, usage, and demographic patterns on the
                Website and its Services; (e) diagnose or fix technology
                problems; and (f) otherwise to plan for and enhance the service.</li><br />
              <li>Website uses certain third-party analytics tools to measure
                traffic and usage trends for the Services. These tools collect
                information, which is not personal or sensitive in nature sent by
                the User’s device, including the web pages visited, add-ons, and
                other information that assists the Website in improving the
                Services. Such information is collected from Users in the form of
                anonymized logs, so that it cannot reasonably be used to
                identify any particular individual User.</li>
            </ul>
            <br /><br />
            CONFIDENTIAL: The website aspires to takes care of all the information
            provided to it by its Users which may be termed as confidential. Such
            confidential information which is not required to be disclosed to the
            website, is specifically excluded from the definition of Personal
            Information and shall not be collected/used. The confidential
            information of the User shall not be disclosed or shared by the
            Websites, its employees, its agents or any third-party contractors
            including the experts either orally or in writing except for the following
            circumstances:
            <br /><br />
            <ul>
              <li>If Website believes that there is a significant/ real/ imminent
                threat or risk to User’s health, safety or life or to the health, safety
                or life of any other person or the public.</li><br />
              <li>If such confidential information must be shared in accordance
                with the law inclusive of any investigation, Court summons,
                judicial proceedings etc.
              </li>
              <br />

              <li>To protect and defend the rights or property of the Website</li>

            </ul>
            <br />
            <br />
            CHILDREN PRIVACY POLICY: The Website requires that the User visiting
            and using the services are above 18 years of age however some
            service information is accessible to children under the age of 18 as
            well. However, it is stressed upon that website is not designed or
            intended to be attractive to be used by children under the age of 13
            and no personal identifiable information of children below the age of 13 is collected knowingly. IF YOU ARE UNDER 13 YEARS OF AGE, PLEASE DO
            NOT USE ANY OF THE SERVICE PROVIDED BY THE WEBSITE AT ANY TIME OR
            IN ANY MANNER. If it comes to the knowledge of the concerned parent
            regarding sharing of any information of a child under the age of 13,
            contact the Website immediately. We will take appropriate steps and
            delete the data from the Website’s systems.
            <br />
            <br />
            Safety and Security: Unzzip Truth.com honors users' privacy and
            employs the best practice to secure the user's personal details, such
            as birth details, address, etc., and also financial details such as credit
            card or debit card transaction details. Unzzip Truth.com uses the best
            encryption methodologies to ensure secure transactions from our
            side and thus encourages our clients to use their credit/debit cards
            on Unzzip Truth.com with full confidence. By doing so, we strive for the
            safety and security of our users, thus making their experience with
            Unzzip Truth.com absolutely safe and secure.
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}
const Ad = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // Optionally, you can also scroll to the top when the component unmounts
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <>
      <NavBar />

      <div style={{  backgroundColor: "var(--white)" }}>
        <div className='container' style={{}}>
          <h2 style={{ textAlign: "center", fontSize: "30px" }}>Contact Us</h2>
          <p style={{ fontSize: "20px", padding: "20px 10px" }}>
            <strong>Address:</strong> Floor No.1, Nawada,
            Dehradun, Uttarakhand, 248005
            <br /><br />
            <strong>Email : </strong>support@unzziptruth.com<br /><br />

          </p>
        </div>
      </div>
      <Footer />

    </>
  )
}
const Tc = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // Optionally, you can also scroll to the top when the component unmounts
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <>
      <NavBar />

      <div style={{  backgroundColor: "var(--white)" }}>
        <div className='container' style={{}}>
          <h2 style={{ textAlign: "center", fontSize: "30px" }}>Term & Condition</h2>
          <p style={{ fontSize: "20px", padding: "20px 10px" }}>
            These terms and conditions of Use (hereinafter referred as “Terms of
            Usage”) describe and govern the User’s use of the content and
            services offered by Unzzip Truth through www.unzziptruth.com
            (hereinafter referred as “We” “Unzzip Truth” “us” “our” “Unzzip Truth
            application” “Website”).
            <br />
            <br />
            <strong>UPDATION
            </strong>
            <br /><br />
            The Website may update/amend/modify these Terms of Usage from
            time to time. The User is responsible to check the Terms of Usage
            periodically to remain in compliance with these terms.
            <br /><br />
            <strong>USER CONSENT</strong>
            <br /><br />
            By accessing the Website and using it, you (“Member”, “You”, “Your”)
            indicate that you understand the terms and unconditionally &
            expressly consent to the Terms of Usage of this Website. If you do not
            agree with the Terms of Usage, please do not click on the “I AGREE”
            button. The User is advised to read the Terms of Usage carefully before
            using or registering on the Website or accessing any material,
            information or services through the Website. Your use and continued
            usage of the Website (irrespective of the amendments made from
            time to time) shall signify your acceptance of the terms of usage and
            your agreement to be legally bound by the same.
            <br />
            <br />
            <strong>GENERAL DESCRIPTION
            </strong>
            <br />
            <br />
            The Website is an internet-based portal having its existence on World
            Wide Web, Application and other electronic medium and provides
            astrological content, reports, data, telephone, video and email
            consultations (hereinafter referred as “Content”). The Website is
            offering “Free Services” and “Paid Services” (Collectively referred as
            “Services”). Free Services are easily accessible without becoming a
            member however for accessing the personalised astrological services
            and/or receive additional Content and get access to Paid Services,
            You are required to register as a member on the portal. By registering
            for Paid Services, a Member agrees to:
            <ul>
              <li>To provide current, complete, and accurate information about
                himself as prompted to do so by the Website</li>
              <br />
              <li>To maintain and update the above information as required and
                submitted by you with the view to maintain the accuracy of the
                information being current and complete.</li>
              <br />


            </ul>
            <br />
            <strong>REGISTRATION AND ELIGIBILITY
            </strong>
            <br />
            <br />
            <ul><li>By using this website, you agree that you are over the age of 18
              years and are allowed to enter into a legally binding and
              enforceable contract under Indian Contract Act, 1872. The
              Website would not be held responsible for any misuse that may
              occur by virtue of any person including a minor using the
              services provided through the Website. You are however allowed
              to ask questions related to minors in your family as per the terms
              and conditions outlined herewith in this policy.</li>
              <br />
              <li>For the User to avail the services, the User will be directed to
                Register as a Member on the Website whereby You (User) agree
                to provide update, current and accurate information while filling
                up the sign-in form. All information that you fill and provide to
                the Website and all updates thereto are referred to in these
                Terms of Usage as “Registration Data“.
              </li>
              <br />
              <li>An account could be created by you through the Website ID
                (Your Phone Number) and password (OTP) or other log - in ID
                and password which can include a facebook, gmail or any
                other valid email ID. The User while creating an account hereby
                represents and warrants that all the information provided by the
                User is current, accurate and complete and that the User will
                maintain the accuracy and keep the information updated from
                time to time. Use of another User’s account information for
                availing the services is expressly prohibited. If in case it is found
                that the information so supplied on the Website is inaccurate,
                incomplete, untrue and not current, the Website has the right to
                suspend or terminate the User’s account and restrict/refuse the
                use of the Website by such User in future.</li>
              <br />
              <li>The right to use this Website is personal to the User and is not
                transferable to any other person or entity. The User would be
                responsible for protecting the confidentiality of User’s
                passwords and other information required for the purposes of
                registration. The User would be fully responsible for all the
                activities that occur under the User’s account with the Website.
                The Website cannot and will not be liable for any loss or damage
                arising from the User’s failure to maintain secrecy and
                confidentiality. The User shall notify the Website immediately if
                they become aware of any unauthorized use of their Account(s)
                or breach of any security. The User must log out from its account
                at the end of the session</li>
              <br />
              <li>The User while availing any service shall be informed whether
                the service so rendered is personal to the Website or is available
                from a Third party. The Website shall have no control or
                monitoring on the information disseminated to any third party
                via the Website</li>
              <br />
              <li>The User agrees, understands and confirms that his/ her
                personal data including without limitation to details relating to
                debit card/ credit card transmitted over the Internet may be
                susceptible to misuse, hacking, theft and/ or fraud and that the
                Website or the Payment Service Provider(s) have no control over
                such matters.</li>
              <br />
              <li>The Website does not permit the use of the Services by any User
                under the following conditions: -
              </li>
              <br />
              <li>If the User is a resident of any jurisdiction that may prohibit the
                use of the Services rendered by the Website.</li>
              <br />
              <li>If the User is a resident of any State/Country that prohibits by
                way of law, regulation, treaty or administrative act for entering
                into trade relations or/and
              </li>
              <br />
              <li>Due to any religious practices.</li>
              <br />
              <li>If the User has created multiple accounts using various mobile
                numbers. The User may not have more than one active account
                with the Website.</li>
              <br />

            </ul>
            <br />
            <strong>WEBSITE CONTENT
            </strong>
            <br />
            <br />
            <ul>
              <li>The Website and any individual Websites which may be available
                through external hyperlinks with the Website are private property.</li>
              <br />
              <li>All interaction on this Website inclusive of the guidance and advice
                received directly from the Licensed Provider must comply with these
                Terms of Usage.</li>
              <br />
              <li>The User shall not post or transmit through this Website any material
                which violates or infringes in any way upon the rights of others, or any
                material which is unlawful, abusive, defamatory, invasive of privacy,
                vulgar, obscene, profane or otherwise objectionable, which
                encourages conduct that would constitute a criminal offence, give
                rise to civil liability or otherwise violate any law.</li>
              <br />
              <li>The Website shall have a right to suspend or terminate access by
                such User or terminate the User’s registration and such User shall not
                gain access to the Website.</li>
              <br />
              <li>The Website reserves the right to terminate the access or to change
                or discontinue any aspect or feature of the Website including, but not
                limited to, content, graphics, deals, offers, settings, etc.</li>
              <br />
              <li>Any information other the guidance and advice, received directly
                from the Third-Party Service Provider, the educational, graphics,
                research sources and other incidental information on the Site, the
                content, should not be considered as medical advice.</li>
              <br />
              <li>The Website does not take guarantee regarding the medical advice,
                if provided, by the third-party service provider inclusive of registered
                astrologers with the site. The User should always talk to an
                appropriately qualified health care professional for diagnosis and
                treatment including information regarding which medications or
                treatment may be appropriate for the User. None of the Content
                represents or warrants that any particular medication or treatment is
                safe, appropriate, or effective for you. Unzzip Truth does not endorse
                any specific tests, medications, products or procedures.</li>
              <br />
              <li>The Website does not take guarantee of any untoward incident that
                may happen with the User after seeking the Service. The Website or
                the Service Provider providing the advice is not liable and does not
                guarantee any results as expected by the User and accessing the
                Website in such scenario is purely at the risk of the User.</li>
              <br />
              <li>By using the Site, Application or Services, User hereby agrees that any
                legal remedy or liability that you seek to obtain for actions or
                omissions of other Members inclusive of the service provider
                registered with the Website or other third parties linked with the
                Website, shall be limited to claim against such particular party who
                may have caused any harm. You agree not to attempt to impose
                liability on or seek any legal remedy from the Website with respect to
                such actions or omissions.</li>
              <br />

            </ul>
            <br />
            <strong>USER ACCOUNT ACCESS</strong>
            <br /><br />
            The Website shall have access to the account and the information
            created by the User for ensuring and maintaining the high-quality
            services provided by the Website and for addressing the need of the
            customer in the most effective manner. User hereby consents for the
            unconditional access of the account by the Website, its employees,
            agents and other appointed person in such regard. For the purpose of
            addressing the complaints (if any received) and any suspected abuse
            reported, the Website shall investigate on case-to-case basis from
            the records available. The User is directed to read the terms provided
            in the Privacy Policy as regards such records.
            <br /><br />
            <strong>PRIVACY POLICY</strong>
            <br /><br />
            The User hereby consents, expresses and agrees that the User has
            read and fully understand the Privacy Policy of the Website. The User
            further consents that the terms and contents of such Privacy policy is
            acceptable to the User inclusive of any update/alteration/change
            made and duly displayed on the Website.
            <br /><br />
            <strong>BREACH AND TERMINATION</strong>
            <br /><br />
            <ul>
              <li>The Website may, in whole or in part, without informing the User,
                modify, discontinue, change or alter the services ordered or the
                Account of the User registered with the Website. The Website may or
                may not issue notice or provide any reason for such action taken by
                the Website.</li>
              <br />
              <li>Violation of any conditions mentioned in this Terms of Usage shall
                lead to immediate cancellation of the Registration of the User, if
                registered with the Website. The Website reserves right to terminate
                and initiate action immediately, if:
              </li>
              <br />
              <li>The Website is not able to verify and authenticate the Registration
                data or any other relevant information provided by the User.</li>
              <br />
              <li>The Website believes that the actions of the User may cause legal
                liability for the Website, other Users or any service provider linked with
                the Website.</li>
              <br />
              <li>The Website believes that the User has provided the Website with
                false and misleading Registration Data or there is interference with
                the other Users or the administration of the services, or have violated
                the privacy policy as listed by the Website.</li>
              <br />
              <li>For the Service Provider inclusive of the Astrologer, You understand
                and agree that your relationship with the Website is limited to being
                a member and You act exclusively on your own behalf and for your
                own benefit. The Website may terminate and de-activate the Profile
                of such service provider for any violation of the present terms of
                usage and the Service Terms and Conditions agreed upon between
                the parties while registration of the data by such Service Provider.</li>
            </ul>
            <br /><br />
            <strong>DELIVERY, CANCELLATION AND REFUND
            </strong>
            <ul><li>No refund shall be processed on the order of any reports under any
              circumstances if the order has reached the “processing” (Assigned to
              an Astrologer) stage. The risk and liability of placing order in a haste
              and careless manner totally lies with the User and the Website is not
              responsible for any refund once the processing stage has started.</li>
              <br />
              <li>No refund shall be processed once the Order has been placed and
                executed. However, if the User intends to cancel a successfully placed
                order before execution, the User is required to contact the customer
                care team within 1 (one) hour of making the payment, whereafter it is
                totally at the discretion of the Website whether to issue refund.</li>
              <br />
              <li>Any technical delay or glitch reported in the Website during the
                processing of the request which includes generating reports by the
                service provider i.e. Astrologer shall not be eligible for claiming
                refund. The User agrees that the timelines are approximate and all
                essentials steps would be taken to adhere to the timelines as
                displayed.</li>
              <br />
              <li>No refund shall be processed for the reason that in-correct
                information or data has been provided by You. The User agrees to be
                careful while providing any information to the Website and must recheck the information filled before clicking on “Submit”. The User can
                request for change in the in-correct information or data entered
                provided, the request for such change has been made with the
                customer care within 1 (one hour) of execution of the service rendered
                by the service provider.</li>
              <br />
              <li>No refund shall be processed for return of any damaged product. The
                User undertakes and agrees that by ordering any product as
                displayed on the Website, the Registered User shall be fully
                responsible for any damaged caused to the product, post its delivery.
                For orders made via “Cash on Delivery” method of payment, the User
                shall be charged for the cost of the product as displayed by the
                Website and the shipping/custom/courier charges as applicable, if
                the product is returned.</li>
              <br />
              <li>Refund on pro-rata basis may be considered for any delay in the
                activation of the subscription services and any damage that may be
                caused to the product while in transit shall be dealt by the Website
                and its agencies.</li>
              <br />
              <li>You agree that the display picture for the products listed for purchase
                by the User are for reference purpose only and the Website will try to
                deliver the product ordered in an as-is condition as displayed on the
                Website. The User is advised to exercise discretion in such case and
                no refund shall be issued on such grounds.
              </li>
              <br />
              <li>The services offered and the products sold are strictly not meant to
                replace any philosophical, emotional or medical treatment. The
                Website holds no responsibility or liability about the reality or
                reliability of the astrological effects on the human physiology, by the
                gems represented and sold on the Website. The placing of order for
                buying such products or taking the services is solely on the discretion
                and will of the User and the Website does not have any responsibility
                upon the products sold. The User is advised to exercise discretion in
                such case and no refund shall be issued on such grounds.</li>
              <br />
              <li> No refund shall be processed for providing a wrong contact number
                for the purpose of availing the “Call with Astrologer” feature. The User
                once opted for this feature is advised to keep the Contact Number in
                full coverage area and must answer the call when received. No refund
                shall be processed for any call which gets connected.
              </li>
              <br />
              <li>The refunds, if any, shall be processed after deduction of the
                transaction charges levied by the Bank and/or the Payment Gateway,
                to & fro cost of the shipping and/or courier charges (With regard to
                purchase of a product listed on the Website), customs duty (if levied)
                and/or any other charges that may have been incurred by the
                Website during processing and/or delivering the service, as
                applicable.</li>
              <br />
              <li>In case the Website or Payment gateway’s webpage, that is linked to
                the Website, is experiencing any server related issues like ‘slow down’
                or ‘failure’ or ‘session timeout’, the User shall, before initiating the
                second payment, check whether his/her Bank Account has been
                debited or not and accordingly resort to one of the following options :</li>
              <br />
              <li>In case the Bank Account appears to be debited, ensure that you do
                not make the payment twice and immediately thereafter contact the
                Website via customer care to confirm payment.</li>
              <br />
              <li>In case the Bank Account is not debited, the User may initiate a fresh
                transaction to make payment.</li>
              <br />
              <li>However, refund for multiple payment, if any, even after the above
                precaution against the same order shall be refunded in full without
                deduction of the transaction charges as mentioned above. The
                Website shall only retain the cost of one single order as intended to
                be placed by the User.</li>
              <br />
              <li>If there are orders that the Website is unable to accept and must
                cancel, the Website at its sole discretion, reserves the right to refuse
                or cancel any order for any reason whatsoever. Some situations may
                result in the order being cancelled and include, without limitation,
                non-availability of the service, inaccuracy, error in pricing
                information or other problems as identified. If the User’s order is
                cancelled after charges being paid against the said service, the said
                amount paid for booking shall be refunded.
              </li>
              <br />
              <li>By requesting for refund, user is agreeing to provide Unzzip Truth’s
                quality audit team permission to access the chat/call recording of
                the consultation for which refund has been requested, in order to
                determine whether the case is eligible for refund or not.</li>
              <br />
              <li>Unzzip Truth quality audit team, at best effort basis, provide
                partial/full refunds to the users in their Unzzip Truth wallet wherever
                consultant’s quality parameters are not satisfied. It can take upto 72
                hours to analyse and refund the amount back to Unzzip Truth wallet</li>
              <br />
              <li>Note: All refunds will be credited to user’s Unzzip Truth wallet.</li>
              <br />
              <li>
                Refunds will only be considered in the following cases:
              </li>
              <br />
              <li>Network issue due to which chat/call was affected in between or there
                was a weak signal, background noise, inaudible consultant etc during
                the video/normal call sessions</li>
              <br />
              <li>Consultant is unable to respond fluently in the language mentioned
                in their profile</li>
              <br />
              <li>Consultant is taking inordinately long enough to respond back to the
                user</li>
              <br />
              <li>Consultant has responded back in irrelevant or inappropriate
                response to the query asked by the user</li>
              <br />
              <li>Please Note: No refund will be given in case of lack of accuracy of any
                consultation. Unzzip Truth takes no responsibility for factual accuracy
                on any consultations.
              </li>
              <br />
              <li>No physical product is shipped outside India. Astromall products
                which require physical delivery are only deliverable within India.</li>
            </ul>
            <br />
            <br />
            <strong>USER OBLIGATION
            </strong>
            <br /><br />
            The User (inclusive of the astrologer and the Member Customer)
            under an obligation not to violate the privacy policy, terms and
            conditions and any other terms as defined on the Website. The User
            represents that he is an individual and not a corporation or other legal
            business entity. The rights to use the Website’s services is personal to
            the User.The User shall while using the Website and engaged in any
            form of communication on any of the forums inclusive of the products
            listed on the Website shall not violate the terms and conditions which
            are inclusive of:-
            <br />
            <br />
            <ul>
              <li>The User shall not Post, publish or transmit any messages that is false,
                misleading, defamatory, harmful, threatening, abusive, harassing,
                defamatory, invades another's privacy, offensive, promotes racism,
                hatred or harm against any individual or group or religion or caste,
                infringes another's rights including any intellectual property rights or
                copyright or trademark, violates or encourages any conduct that
                would violate any applicable law or regulation or would give rise to
                civil liability.
              </li>
              <br />
              <li>The User shall not upload or post or otherwise make available any
                content that User do not have a right to make available, under any
                law or under contractual or fiduciary relationships.</li>
              <br />
              <li>The User shall not upload or post or otherwise make available any
                content that infringes any patent, trademark, trade secret, copyright
                or other proprietary rights of any party. The User may, however, post
                excerpts of copyrighted material so long as they adhere to Fair Use
                guidelines.</li>
              <br />
              <li>The User shall not collect screen names and email addresses of
                members who are registered on the Website for purposes of
                advertisement, solicitation or spam.</li>
              <br />
              <li>The User shall not send unsolicited email, junk mail, spam, or chain
                letters, or promotions or advertisements for products or services.</li>
              <br />
              <li>The User shall not upload or distribute files that contain viruses,
                corrupted files, or any other similar software or programs that may
                damage the operation of the Website or another’s computer.</li>
              <br />
              <li>The User shall not engage in any activity that interferes with or
                disrupts access to the Website
              </li>
              <br />
              <li>The User shall not attempt to gain unauthorized access to any portion
                or feature of the Website, any other systems or networks connected
                to the Website, to any of the services offered on or through the
                Website, by hacking, password mining or any other illegitimate
                means.</li>
              <br />
              <li>
                The User shall not violate any applicable laws or regulations for the
                time being in force within or outside India. The use and continuous
                use of the Website is subject to but not limited to using the services
                for personal use.

              </li>
              <br />
              <li>The User shall not resell or make any commercial use of the Services
                without the express written consent from the Website.</li>
              <br />
              <li> The User shall not violate these Terms of Usage including but not
                limited to any applicable Additional terms of the Website contained
                herein or elsewhere.
              </li>
              <br />
              <li>The User shall not Reverse engineer, modify, copy, distribute, transmit,
                display, perform, reproduce, publish, license, create derivative works
                from, transfer, or sell any information or software obtained from the
                Website.</li>
              <br />
              <li>The User by becoming a Registered member of the Website agrees to
                the following situations, which list is not exhaustive and may include
                services incidental to the below mentioned: -</li>
              <br />
              <li>The user agrees to receive communication through the app/website
                through email/SMS or any other communication medium including
                though Whatsapp Business Messages in regards to the usage of the
                app/website.</li>
              <br />
              <li>The User agrees not to transmit via the Website any unlawful,
                harassing, libelous, abusive, threatening, harmful, vulgar, obscene or
                otherwise objectionable material of any kind or nature.
              </li>
              <br />
              <li>The User not to transmit any material that encourages conduct that
                could constitute a criminal offense, give rise to civil liability, or
                otherwise violate any applicable local, state, national or international
                law or regulation. Attempts to gain unauthorized access to other
                computer systems are prohibited.</li>
              <br />
              <li>The User shall not interfere with any other members' use or enjoyment
                of the Website or Services.</li>
              <br />
              <li>The User is under an obligation to report any misuse or abuse of the
                Site. If you notice any abuse or misuse of the Site or anything which is
                in violation of this Agreement, you shall forthwith report such violation
                to Website by writing to Customer Care. On receipt of such complaint,
                Website may investigate such complaint and if necessary, may
                terminate the membership of the Member responsible for such
                violation abuse or misuse without any refund of the subscription fee.</li>
              <br />
              <li>Any false complaint made by a Member shall make such Member
                liable for termination of his / her membership without any refund of
                the subscription fee.</li>
              <br />
              <li>The Website reserves the right to withdraw its services to any
                customer who is found to be unreasonable or abusive during their
                conversation with the Service Provider inclusive of astrologer
                regardless of any reason.</li>
              <br />

            </ul>
            <br />
            While the Website shall take all steps to resolve any situation that is
            in violation of the above obligations arises, however if the situation is
            not controllable, the Website reserves its right to send a written
            warning henceforth. Such violations, if repeated by the User, shall lead
            to a total ban for transacting on the platform by such User. If any
            balance is present in the wallet of the User, the same shall be
            refunded subject to the other charges that may be applicable for such
            violations.
            <br /><br />
            <strong>BANK ACCOUNT INFORMATION</strong>
            <br /><br />
            The User is under an obligation to provide his banking information as
            and when required. For that purpose, the obligation of the User are:-
            <br /><br />
            <ul>
              <li>The User agrees that the debit/credit card details provided by him/
                her for use of the aforesaid Service(s) must be correct and accurate
                and that the User shall not use a debit/ credit card, that is not lawfully
                owned by him/ her or the use of which is not authorized by the lawful
                owner thereof. The User further agrees and undertakes to provide
                correct and valid debit/credit card details.</li>
              <br />
              <li>
                The User may pay the fees required, to the Website by using a
                debit/credit card or through online banking account. The User
                warrants, agrees and confirms that when he/ she initiates a payment
                transaction and/or issues an online payment instruction and provides
                his/ her card / bank details:
              </li>
              <br />
              <li>The User is fully and lawfully entitled to use such credit / debit card,
                bank account for such transactions;</li>
              <br />
              <li>The User is responsible to ensure that the card/ bank account details
                provided by him/ her are accurate;</li>
              <br />
              <li>The User is responsible to ensure sufficient credit is available on the
                nominated card/ bank account at the time of making the payment to
                permit the payment of the dues payable or the bill(s) selected by the
                User inclusive of the applicable Fee.
              </li>
              <br />
            </ul>
            <br />
            The User further agrees that if any part of these Terms of Usage are
            determined to be invalid or unenforceable pursuant to applicable law
            including, but not limited to, the warranty disclaimers and liability
            limitations set forth herein, then the invalid or unenforceable provision
            will be deemed superseded by a valid, enforceable provision that
            most closely matches the intent of the original provision and the
            remainder of these Terms of Usage shall continue in effect.
            <br /><br />
            <strong>DISCLAIMER / LIMITATION OF LIABILITY / WARRANTY</strong>
            <br />
            <br />
            The User expressly understands and agree that, to the maximum
            extent permitted by applicable law, the Website does not provide
            warranties for the service. Astrological counselling provided through
            the Website is based on cumulative or individual knowledge,
            experience and interpretations of astrologers and as such, it may vary
            from one astrologer to another.
            <br />
            <br />
            <ul>
              <li>The Website is offering services through a diverse panel of Astrologers
                duly verified by the Website and such Service Provider (Astrologer)
                may from time to time make recommendations of using mantras,
                jantras, gemstones or other astrological remedies to be used by User.
                Such recommendations are being made in good faith by the
                astrologers and the Website and its subsidiaries, affiliates, officers,
                employees, agents, partners, and licensors make no warranty that:</li>
              <br />
              <li>the service will meet your requirements</li>
              <br />
              <li>the service will be uninterrupted, timely, secure or error - free</li>
              <br />
              <li>the results that may be obtained from the use of the service will be
                accurate or reliable</li>
              <br />
              <li>
                the quality of any products, services, information or other material
                purchased or obtained by you through the service will meet your
                expectations and
              </li>
              <br />
              <li>any errors in the software will be corrected. You are required to make
                full disclosure about the emotional, mental and physical state of the
                person seeking advice from the panel of astrologers of Website so
                that the astrologers make an informed judgment about giving advice.
              </li>
              <br />
              <li>The Website, services and other materials are provided by the
                Website on an "as is" basis without warranty of any kind, express,
                implied, statutory or otherwise, including the implied warranties of
                title, non-infringement, merchantability or fitness for a particular
                purpose. without limiting the foregoing, the Website makes no
                warranty that (i) the Website or the services will meet your
                requirements or your use of the Website or the services will be
                uninterrupted, timely, secure or error-free; (ii) the results that may be
                obtained from the use of the Website, services or materials will be
                effective, accurate or reliable; (iii) the quality of the Website, services
                or other materials will meet your expectations; or that (iv) any errors
                or defects in the Website, services or other materials will be corrected.
                no advice or information, whether oral or written, obtained by the User
                from the Website or through or from use of the services shall create
                any warranty not expressly stated in the terms of use.</li>
              <br />
              <li>To the maximum extent permitted by applicable law, the Website will
                have no liability related to User content arising under intellectual
                property rights, libel, privacy, publicity, obscenity or other laws. The
                Website also disclaims all liability with respect to the misuse, loss,
                modification or unavailability of any User content.</li>
              <br />
              <li>The Website will not be liable for any loss that the User may incur as
                a consequence of unauthorized use of their account or account
                information in connection with the Website or any services or
                materials, either with or without the User’s knowledge. The Website
                has endeavored to ensure that all the information on the Website is
                correct, but the Website neither warrants nor makes any
                representations regarding the quality, accuracy or completeness of
                any data, information, product or service. The Website shall not be
                responsible for the delay or inability to use the Website or related
                functionalities, the provision of or failure to provide functionalities, or
                for any information, software, products, functionalities and related
                graphics obtained through the Website, or otherwise arising out of the
                use of the Website, whether based on contract, tort, negligence, strict
                liability or otherwise. further, the Website shall not be held responsible
                for non-availability of the Website during periodic maintenance
                operations or any unplanned suspension of access to the Website
                that may occur due to technical reasons or for any reason beyond
                the Website's control.</li>
              <br />
              <li>The User understands and agrees that any material or data
                downloaded or otherwise obtained through the Website is done
                entirely at their own discretion and risk and they will be solely
                responsible for any damage to their computer systems or loss of data
                that results from the download of such material or data. The Website
                is not responsible for any typographical error leading to an invalid
                coupon. The Website accepts no liability for any errors or omissions,
                with respect to any information provided to you whether on behalf of
                itself or third parties.</li>
              <br />
              <li>
                The Services provided by the Website are for entertainment purposes
                only and the Website on behalf of itself and its suppliers, disclaims all
                warranties of any kind, express or implied, including without limitation
                any warranty of merchantability, fitness for a particular purpose, title,
                non-infringement and it makes no warranty or representation
                regarding the results that may be obtained from the use of content
                or services, the accuracy or reliability of any content obtained
                through the Services, any goods or services purchased or obtained
                through the Website, and makes no warranty that the services will
                meet your requirements, be uninterrupted, timely, secure or errorfree. No advice or information, whether oral or written, obtained by
                you from the Website shall create any warranty.
              </li>
              <br />
              <li>The services may consist of the following, without limitation:
                Astrological content, Reports, Tarot readings, fortunes, numerology,
                predictions, live telephone consultations, email consultations or
                products sold through Unzzip Truth Shop. Unzzip Truth charges for the
                chat/call service offered on this platform on per minute basis and
                holds no responsibility or liability about the reality or reliability of the
                astrological effects on the human physiology, by the gems, any other
                products or services represented and sold on the website. No advice
                or information, whether oral or written, obtained by you shall create
                any warranty.
              </li>
              <br />
              <li>
                The advisors/consultants/astrologers are also members of the site
                and not employees of the Website or the company. However, the
                Website verifies the degrees, qualifications, credentials, and
                background of the advisors/consultants/astrologers but does not
                refer, endorse, recommend, verify, evaluate or guarantee any advice,
                information or other services provided by the
                advisors/consultants/astrologers or by the company, nor does it
                warrant the validity, accuracy, completeness, safety, legality, quality,
                or applicability of the content, anything said or written by, or any
                advice provided by the advisors/consultants/astrologers.
              </li>
              <br />
              <li>The website is not a suicide helpline platform. If you are considering
                or contemplating suicide or feel that you are a danger to yourself or
                to others, you may discontinue use of the services immediately at
                your discretion and please notify appropriate police or emergency
                medical personnel. If you are thinking about suicide, immediately call
                a suicide prevention helpline such as AASRA (91-22-27546669).</li>
              <br />
              <li>The Website shall not be liable for any inaccuracy, error or delay in, or
                omission of (a) any data, information or message, or (b) the
                transmission or delivery of any such data, information or message; or
                (c) any loss or damage arising from or occasioned by any such
                inaccuracy, error, delay or omission, non-performance or interruption
                in any such data, information or message. Under no circumstances
                shall the Website and/or the payment service providers, its
                employees, directors, and its third party agents involved in
                processing, delivering or managing the services, be liable for any
                direct, indirect, incidental, special or consequential damages, or any
                damages whatsoever, including punitive or exemplary arising out of
                or in any way connected with the provision of or any inadequacy or
                deficiency in the provision of the services or resulting from unauthorized access or alteration of transmissions of data or arising
                from suspension or termination of the services.
              </li>

            </ul>
            <br />
            <br />
            Notwithstanding anything to the contrary contained herein, Unzzip
            Truth liability to you for any cause whatsoever, and regardless of the
            form of the action, will at all times be limited to the amount paid, if
            any, by you to the Website, for the service during the term of
            membership.

            <br />
            <br />
            <strong>INDEMNIFICATION</strong>
            <br /><br />
            The User shall indemnify, defend and hold harmless the Website and
            its parent, subsidiaries, affiliates, officers, directors, employees,
            suppliers, consultants and agents from any and all third party claims,
            liability, damages and/or costs (including, but not limited to,
            attorney’s fees) arising from Your use of the Services, Your violation of
            the Privacy Policy or these Terms of Service, or Your violation of any
            third party's rights, including without limitation, infringement by You
            or any other user of Your account of any intellectual property or other
            right of any person or entity. These Terms of Service will inure to the
            benefit of Website’s successors, assigns, and licensees.
            <br />
            <br />
            <strong>PROPRIETARY RIGHTS TO CONTENT</strong>
            <br />
            <br />
            The User acknowledges that the Content, including but not limited to
            text, software, music, sound, photographs, video, graphics or other
            material contained in sponsor advertisements or distributed via
            email, commercially produced information presented to Member by
            the Website, its suppliers, and/or advertisers, is protected by
            copyrights, trademarks, service marks, patents and/or other
            proprietary rights and laws. The User is not permitted to copy, use,
            reproduce, distribute, perform, display, or create derivative works
            from the Content unless expressly authorized by the Website, its
            suppliers, or advertisers. Moreover, the content such as images, text,
            designs, etc on all of the portals of the Website are taken from various
            online portals such as Google Images. Unzzip Truth is not liable for any
            copyrights of that content or data.
            <br />
            <br />
            <strong>Restricted Content
            </strong>
            <br />
            <br />
            Child Endangerment
            <br />
            Our App prohibits users from creating, uploading, or distributing
            content that facilitates the exploitation or abuse of children will be
            subject to immediate deletion of the account. This includes all child
            sexual abuse materials. To report content on the Unzzip Truth App that
            may exploit a child,
            <br />
            <br />
            We prohibit the use of Unzzip Truth app to endanger children. This
            includes but is not limited to use of apps to promote predatory
            behaviour towards children, such as:
            <br />
            <ul>
              <li>Inappropriate interaction targeted at a child (for example, groping or
                caressing).</li>
              <br />
              <li>Child grooming (for example, befriending a child online to facilitate,
                either online or offline, sexual contact and/or exchanging sexual
                imagery with that child).</li>
              <br />
              <li>Sexualization of a minor (for example, imagery that depicts,
                encourages or promotes the sexual abuse of children or the portrayal
                of children in a manner that could result in the sexual exploitation of
                children).</li>
              <br />
              <li>
                Sextortion (for example, threatening or blackmailing a child by using
                real or alleged access to a child’s intimate images).
              </li>
              <br />
              <li>Trafficking of a child (for example, advertising or solicitation of a child
                for commercial sexual exploitation).</li>
              <br />

            </ul>
            <br />
            We will take appropriate action, if we become aware of content with
            child sexual abuse materials.
            <br />
            <br />
            In addition, content that appeal to children but contain adult themes
            are strictly prohibited, including but not limited to:
            <br />
            <br />
            <ul>
              <li>Content with excessive violence, blood, and gore.</li>
              <br />
              <li>Content that depict or encourage harmful and dangerous activities.
              </li>

            </ul>
            <br />
            <br />
            We also don’t allow users that promote negative body or self-image
            including apps that depict for entertainment purposes plastic
            surgery, weight loss, and other cosmetic adjustments to a person's
            physical appearance.
            <br />
            <br />
            Inappropriate Content
            <br />
            To ensure that Our App remains a safe and respectful platform, we've
            created standards defining and prohibiting content that is harmful or
            inappropriate for our users.
            <br />
            <br />
            Sexual Content and Profanity
            <br />
            We don't allow accounts that contain or promote sexual content or
            profanity, including pornography, or any content or services intended
            to be sexually gratifying. We don’t allow app content that appear to
            promote or solicit a sexual act in exchange for compensation. We
            don’t allow content that contain or promote content associated with
            sexually predatory behaviour, or distribute non-consensual sexual
            content.
            <br />
            <br />
            If an account contains content that violates this policy it gives the
            absolute right to the owner to delete the account with immediate
            effect.
            <br />
            <br />
            Here are some examples of common violations:
            <br />
            <br />
            <ul>
              <li>Depictions of sexual nudity, or sexually suggestive poses in which the
                subject is nude, blurred or minimally clothed, and/or where the
                clothing would not be acceptable in an appropriate public context.</li>
              <br />
              <li>Depictions, animations or illustrations of sex acts, or sexually
                suggestive poses or the sexual depiction of body parts.</li>
              <br />
              <li>Content that depicts or are functionally sexual aids, sex guides, illegal
                sexual themes and fetishes.
              </li>
              <br />
              <li>Content that is lewd or profane – including but not limited to content
                which may contain profanity, slurs, explicit text, or adult/sexual
                keywords in the store listing or in-app.
              </li>
              <br />
              <li>
                Content that depicts, describes, or encourages bestiality
              </li>
              <br />
              <li>Content that promote sex-related entertainment, escort services, or
                other services that may be interpreted as providing or soliciting
                sexual acts in exchange for compensation, including, but not limited
                to compensated dating or sexual arrangements where one
                participant is expected or implied to provide money, gifts, or financial
                support to another participant ("sugar dating").</li>
              <br />
              <li>Content that degrade or objectify people, such as apps that claim to
                undress people or see through clothing, even if labelled as prank or
                entertainment apps.</li>
              <br />
              <li>
                Content or behaviour that attempts to threaten or exploit people in a
                sexual manner, such as creepshots, hidden camera, non-consensual
                sexual content created via deepfake or similar technology, or assault
                content.
              </li>
            </ul>
            <br />
            <br />
            Hate Speech
            <br />
            We don't allow users that promote violence, or incite hatred against
            individuals or groups based on race or ethnic origin, religion,
            disability, age, nationality, veteran status, sexual orientation, gender,
            gender identity, caste, immigration status, or any other characteristic
            that is associated with systemic discrimination or marginalization.
            <br />
            <br />
            Here are examples of common violations:
            <br />
            <br />
            <ul>
              <li>
                Content or speech asserting that a protected group is inhuman,
                inferior or worthy of being hated.
              </li>
              <br />
              <li>Content that is hateful slurs, stereotypes, or theories about a
                protected group possessing negative characteristics (for example,
                malicious, corrupt, evil, etc.), or explicitly or implicitly claims the group
                is a threat.</li>
              <br />
              <li>Content or speech trying to encourage others to believe that people
                should be hated or discriminated against because they are a
                member of a protected group</li>
              <br />
              <li>Content which promotes hate symbols such as flags, symbols,
                insignias, paraphernalia or behaviours associated with hate groups</li>
              <br />

            </ul>
            <br />
            Violence
            <br /><br />
            We don't allow apps that depict or facilitate gratuitous violence or
            other dangerous activities.
            <br />
            <br />
            Here are some examples of common violations:
            <br />
            <br />
            <ul>
              <li>Graphic depictions or descriptions of realistic violence or violent
                threats to any person or animal.</li>
              <br />
              <li>Content that promote self-harm, suicide, eating disorders, choking
                games or other acts where serious injury or death may result.</li>
              <br />

            </ul>
            <br />
            Terrorist Content
            <br />
            <br />
            We do not permit terrorist organizations to publish content for any
            purpose, including recruitment.

            <br />  <br />
            We don't allow users with content related to terrorism, such as content
            that promotes terrorist acts, incites violence, or celebrates terrorist
            attacks
            <br />
            <br />
            Dangerous Organizations and Movements
            <br /><br />
            We do not permit movements or organizations that have engaged in,
            prepared for, or claimed responsibility for acts of violence against
            civilians to publish content for any purpose, including recruitment.
            <br />
            <br />
            We don’t allow users with content related to planning, preparing, or
            glorifying violence against civilians..
            <br />
            <br />  Sensitive Events
            <br />
            <br />We don't allow contents that capitalize on or are insensitive toward a
            sensitive event with significant social, cultural, or political impact,
            such as civil emergencies, natural disasters, public health
            emergencies, conflicts, deaths, or other tragic events.
            <br />
            <br />
            Here are examples of common violations:

            <br />  <br />
            <ul>
              <li>Lacking sensitivity regarding the death of a real person or group of
                people due to suicide, overdose, natural causes, etc.</li>
              <br />
              <li>Denying the occurrence of a well-documented, major tragic event</li>
              <br />
              <li>Appearing to profit from a sensitive event with no discernible benefit
                to the victims.
              </li>
            </ul>
            <br />
            <br />
            Bullying and Harassment
            <br />  <br />
            We don't allow content that contain or facilitate threats, harassment,
            or bullying.
            <br />  <br />
            Here are examples of common violations:
            <br />
            <br />
            <ul>
              <li>Bullying victims of international or religious conflicts.</li>
              <br />
              <li>Content that seeks to exploit others, including extortion, blackmail,
                etc.</li>
              <br />
              <li>Posting content in order to humiliate someone publicly</li>
              <br />
              <li>Harassing victims, or their friends and families, of a tragic event.</li>
              <br /><br />

            </ul>
            Dangerous Products
            <br />
            <br />
            We don't allow users that facilitate the sale of explosives, firearms,
            ammunition, or certain firearms accessories.
            <br />
            <br />
            We don't allow users that provide instructions for the manufacture of
            explosives, firearms, ammunition, restricted firearm accessories, or
            other weapons. This includes instructions on how to convert a firearm
            to automatic, or simulated automatic, firing capabilities.
            <br />
            <br />
            Psychotropic drugs.
            <br />
            <br />
            We don't allow users that facilitate the sale of marijuana or marijuana
            products, regardless of legality. We don't allow users that facilitate the
            sale of tobacco (including e-cigarettes and vape pens) or encourage
            the illegal or inappropriate use of alcohol or tobacco. We don't allow
            users that facilitate the sale of psychotropic drugs or drugs falling
            under the restricted category of Drugs and Cosmetics Act and Rules
            1945.

            <br />  <br />
            Black Magic, Witchcraft, Voodoo and Tantrism
            <br />
            <br />
            We as an Organisation, strictly prohibit our users to get into any form
            of black magic, witchcraft, voodoo and tantrism. In case it comes to
            our information, that a user is indulged intentionally/unintentionally
            in any such activity, we hereby reserve the right to delete the account.
            <br />
            <br />
            Unzzip Truth Services:

            <br />
            <br />  These Terms of Service apply to all users of Unzzip Truth, Information
            provided by our users through the Unzzip Truth Service may contain
            links to third party websites that are not owned or controlled by Unzzip
            Truth. Unzzip Truth has no control over, and assumes no responsibility
            for, the content, privacy policies, or practices of any third party
            websites. Also, Unzzip Truth does not assume any liability for any
            mistakes, misinterpretation of law, defamation, omissions, falsehood,
            obscenity, pornography or profanity in the statements, opinions,
            representations or any other form of third party content on the Site as
            well as that of its users. In addition, Unzzip Truth will not and cannot
            censor or edit the content of any users and third-party site. By using
            the Service, you expressly acknowledge and agree that Unzzip Truth
            shall not be responsible for any damages, claims or other liability
            arising from or related to your use of any content and third-party
            website. You understand that the information and opinions in the content represent solely the thoughts of the author and is neither
            endorsed by nor does it necessarily reflect Unzzip Truth’s belief.
            <br />
            <br />
            Errors, Corrections, and Right to Modify or Discontinue Service and
            Sites:
            <br />
            <br />
            We do not represent or warrant that the Unzzip Truth Site will be errorfree, free of viruses or other harmful components, or that defects will
            be corrected. We do not represent or warrant that the information
            available on or through the Unzzip Truth will be correct, accurate,
            timely or otherwise reliable. We may make changes to the features,
            functionality or content of the Unzzip Truth at any time. We reserve the
            right in our sole discretion to edit or delete any documents,
            information or other content appearing on the Site. Unzzip Truth
            reserves the right at any time and from time to time to modify or
            discontinue, temporarily or permanently, the Services and/or Sites (or
            any part thereof) with or without notice. Unzzip Truth shall not be liable
            to you or to any third party for any modification, suspension or
            discontinuance of the Service or any Sites.

          </p>
        </div>
      </div>
      <Footer />

    </>
  )
}
