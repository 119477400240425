import React, { useEffect, useState } from 'react'
import Carouse from '../../Component/TopCarouselComponent/Carouse'
import android from '../../../images/download/GooglePlay.png'
import { BsStarHalf, BsStarFill } from 'react-icons/bs'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link, useNavigate } from 'react-router-dom'
import "./ok.css"
// import { TbZodiacAquarius, TbZodiacAries, TbZodiacCancer, TbZodiacCapricorn, TbZodiacGemini } from "react-icons/tb";
import img from "../../../images/female_dummy.jpg"
import { FaArrowRight } from "react-icons/fa";
// import { GetAstrologers } from '../../../api/userLogInReducer';
import { useDispatch, useSelector } from 'react-redux';
import { GetAstrologers, Horoscopy } from '../../../api/userLogInReducer';
import { NavBar } from '../../Component/All';
import { ClientChat } from '../../../api/ChatRequestReducer';
import Faq from './Faq';
import { Footer } from '../../..';
import Banner from '../../Component/Banner/Banner';
import Horoscope from '../../Component/Horoscope/Horoscope';
import { Services, Testimonial } from '../../Component/Static/Static';
function Home({ socketRef }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2
    }, mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    },

  }
  // const responsive2 = {
  //   superLargeDesktop: {
  //     breakpoint: { max: 4000, min: 0 },
  //     items: 1
  //   },
  // }
  // const { astrologers } = useSelector((state) => state.userLog)
  const { user } = useSelector((state) => state.userLog)
  // const [zodiac, setZodiac] = useState("aries")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const [horoscope, setHoroscope] = useState({})
  const { astrologers } = useSelector((state) => state.userLog)


  useEffect(() => {
    user?._id && navigate("/search")
  }, [user])

  // useEffect(() => {
  //   zodiac && dispatch(Horoscopy({ sign: zodiac })).then((e) => {
  //     e.payload?.success && setHoroscope(e.payload?.data?.prediction)
  //   })

  // }, [zodiac])
  const { AstroRequest } = useSelector((state) => state.astroRequest)
  const [s, sets] = useState({})

  useEffect(() => {
    const a = setInterval(() => {
      dispatch(GetAstrologers())
    }, 15000);
    dispatch(GetAstrologers())
    return () => clearInterval(a)
  }, [dispatch])
  return (
    <><Banner />
      <Horoscope />
      {/* <div style={{ overflow: "hidden", background: "var(--bg-white)" }}>
        <div className='container' style={{ display: "flex", flexDirection: "column" }}>
          <h2 className='staticHeading' style={{ color: "var(--bg-dark)", textAlign: "center", marginTop: "30px", marginBottom: "40px", fontSize: "40px" }}>Today’s Horoscope</h2>
          <div className='zodiac' style={{ overflowX: "scroll", paddingBottom: "30px", width: "100%" }} >
            <div className='zodiacSing' style={{ display: "flex", gap: "20px", justifyContent: "space-between", }}>
              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("aries")}>
                <img src="/Archive/aries.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>
              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("taurus")}>
                <img src="/Archive/Taurus.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>
              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("gemini")}>
                <img src="/Archive/Gemini.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>
              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("cancer")}>
                <img src="/Archive/Cancer.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>
              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("leo")}>
                <img src="/Archive/Leo.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>
              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("virgo")}>
                <img src="/Archive/Virgo.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>
              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("libra")}>
                <img src="/Archive/Libra.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>
              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("scorpio")}>
                <img src="/Archive/Scorpio.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>
              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("sagittarius")}>
                <img src="/Archive/Sagittarius.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>


              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("capricornus")}>
                <img src="/Archive/Capricornus.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>
              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("aquarius")}>
                <img src="/Archive/Aquarius.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>


              <div style={{ borderRadius: "10px", background: "white", cursor: "pointer", padding: "5px" }} onClick={() => setZodiac("pisces")}>
                <img src="/Archive/Pisces.png" style={{ height: "200px", aspectRatio: "1" }} alt="" />
              </div>
            </div>

          </div>
          <h2 className='staticHeading' style={{ color: "var(--bg-dark)", textAlign: "center", marginTop: "20px", marginBottom: "20px", fontSize: "30px", textTransform: "uppercase" }}>{zodiac}</h2>
          <div style={{ width: "100%", padding: "20px", backgroundColor: "var(--white)", borderRadius: "10px", boxShadow: "0px 0px 10px 0px rgba(128, 128, 128, .5)" }}>
            <h2 className='staticHeading' style={{ color: "var(--bg-dark)", textAlign: "center", marginTop: "0px", marginBottom: "10px", fontSize: "25px", textTransform: "uppercase" }}>Personal</h2>
            <p style={{ fontSize: "20px", textAlign: "center" }}>
              {horoscope.personal}
            </p>
          </div>

          <div onClick={() => navigate("/login")} style={{ display: "flex", boxShadow: "0px 0px 4px rgba(128, 128, 128, 0.5)", overflow: "hidden", gap: "10px", background: "white", width: "400px", borderRadius: "50px", height: "80px", margin: "30px auto", alignItems: "center", overflow: "hidden", }}>
            <p style={{ flex: "1", display: "flex", flexDirection: "column" }}><span style={{ fontSize: "20px", textAlign: "center", fontWeight: "600", textTransform: "capitalize", }}>Read More</span>
            </p>
            <div style={{ cursor: "pointer", height: "100%", aspectRatio: "1", borderRadius: "50%", backgroundColor: "var(--yellow)", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "22px", fontWeight: "500" }}>
              <FaArrowRight size={30} />
            </div>
          </div>

        </div>

      </div> */}
      <div style={{  backgroundColor: "var(--white)" }}>
        <div className='container' style={{}}>
          <h2 className='astroListHeading' >
            Meet Our Psychic Masters
          </h2>
          <p className='astroListSubHeading'>Get psychic reading online to get clarity on your life path</p>
          <div className='astroContainer' >
            {
              astrologers.slice(0, 6).map((e) => (
                <div key={e._id} className='card1' style={{ display: "flex", flex: "1", minWidth: "31%", flexDirection: "column", borderRadius: "15px", overflow: "hidden", }}>
                  <div style={{ backgroundColor: "var(--bg-yellow)", flex: "1", display: "flex", flexDirection: "column", padding: "20px 15px 15px 15px", gap: "8px", paddingBottom: "20px", position: "relative" }}>
                    <div style={{ position: "absolute", display: "flex", gap: "5px", alignItems: "center", padding: "5px 10px", border: "1px solid gray", borderRadius: "4px", bottom: "10px", right: "10px" }}>{
                      e.isOnline === "Online" ? <>
                        <div style={{ height: "10px", aspectRatio: "1", background: "green", borderRadius: "50%" }}></div>
                        <span>Online</span>
                      </> : e.isOnline === "Offline" ? <>
                        <div style={{ height: "10px", aspectRatio: "1", background: "gray", borderRadius: "50%" }}></div>
                        <span>Offline</span>
                      </> : <>
                        <div style={{ height: "10px", aspectRatio: "1", background: "red", borderRadius: "50%" }}></div>
                        <span>Busy</span>
                      </>
                    }</div>
                    <div style={{ display: "flex", gap: "5%", alignItems: "flex-start" }}>
                      <div style={{ height: "100px", minWidth: "100px", position: "relative", maxWidth: "100px", backgroundColor: "red", borderRadius: "50%", overflow: 'hidden', }}>
                        <img src={e?.avatar?.url} alt="" style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <Link to={`/auth?login=true`} style={{ fontSize: "20px", fontWeight: "700", textTransform: "uppercase" }}>{e.name}</Link>
                        <p>{`(${e.reviews.filter((i) => i.rating).length})`}     {` `}{
                          parseFloat(
                            (
                              e.reviews
                                .filter((i) => i.rating)
                                .reduce((accumulator, currentValue) => accumulator + currentValue.rating, 0) /
                              e.reviews.filter((i) => i.rating).length
                            ).toFixed(1)
                          ) || 0
                        } </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", gap: "8px", margin: "10px 0px", flexWrap: "wrap" }}>
                      {
                        e?.category?.length > 0 ? <>
                          {
                            e?.category?.slice(0, 5).map((e) => <>
                              <p style={{ background: "var(--yellow)", boxShadow: "0px 0px 10px var(--gray)", padding: "5px 15px", borderRadius: "20px " }}>{e}</p>
                            </>)
                          }

                        </> : <>

                          <p style={{ background: "var(--yellow)", boxShadow: "0px 0px 10px var(--gray)", padding: "5px 15px", borderRadius: "20px " }}>Astrologer</p>
                        </>
                      }
                      <p onClick={() => navigate(`/astrologer/${e._id}`)} style={{ background: "gray", boxShadow: "0px 0px 10px var(--gray)", padding: "5px 15px", borderRadius: "20px ", color: "white" }}>More...</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
                      <p>{e.experience} years <span>Experience</span></p>
                      <p>{e.consultation} <span style={{ fontWeight: "400" }}>consultations done</span></p>
                    </div>

                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "var(--yellow)", padding: "10px 20px", alignItems: "center" }}>
                    <div style={{ fontSize: "1rem", fontWeight: "600", color: "var(--dark)" }}>
                      {
                        e.isOnline === "Online" ? `$${e?.chargePrise}Per Min` : "$7 Per Question"
                      }

                    </div>
                    <div style={{ background: "var(--dark)", color: "var(--white)", padding: "5px 20px", borderRadius: "3px", cursor: "pointer", textTransform: "uppercase" }} onClick={() => {
                      if (!user?._id) {
                        navigate(`/auth?login=true`)
                      }
                    }}>
                      {
                        e.isOnline === "Online" ? "chat" : "ask"
                      }

                    </div>
                  </div>
                </div>
              ))
            }

          </div>
        </div>
      </div>
      {/* <div className='counter' style={{ overflow: "hidden", background: "var(--bg-dark)" }}>
        <div className='container' style={{ display: "flex", flexDirection: "column" }}>
          <h2 className='staticHeading' style={{ color: "white", textAlign: "center", marginTop: "30px", marginBottom: "70px", fontSize: "40px" }}>Why Unzzip Truth?</h2>
          <div className='counterContainer' style={{ display: "flex", justifyContent: "space-between", color: "white", gap: "20px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "5%", flex: "1", justifyContent: "center" }}>
              <div className='staticNumber' style={{ fontSize: "2.5rem", fontWeight: "600" }}>25+</div>
              <hr style={{ width: "1px", height: "60px" }} />
              <div className='staticTitleTop' style={{ fontSize: "1.6rem", opacity: "0.7" }}>Years of <br /><span className='staticTitleSub' style={{ fontWeight: "500", fontSize: "1.3rem", color: "var(--white)" }}> Service</span></div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5%", flex: "1", justifyContent: "center" }}>
              <div className='staticNumber' style={{ fontSize: "2.5rem", fontWeight: "600" }}>1500+</div>
              <hr style={{ width: "1px", height: "60px" }} />
              <div className='staticTitleTop' style={{ fontSize: "1.6rem", opacity: "0.7" }}>Happy <br /><span className='staticTitleSub' style={{ fontWeight: "500", fontSize: "1.3rem", color: "var(--white)" }}> Customers</span></div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5%", flex: "1", justifyContent: "center" }}>
              <div className='staticNumber' style={{ fontSize: "2.5rem", fontWeight: "600" }}>100%</div>
              <hr style={{ width: "1px", height: "60px" }} />
              <div className='staticTitleTop' style={{ fontSize: "1.6rem", opacity: "0.7" }}> guaranteed<br /><span className='staticTitleSub' style={{ fontWeight: "500", fontSize: "1.3rem", color: "var(--white)" }}>Satisfaction</span></div>
            </div>
          </div>
          <div className='rating' style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "70px", marginTop: "40px" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div>
                <BsStarFill style={{ color: "var(--cta-yellow)" }} />
                <BsStarFill style={{ color: "var(--cta-yellow)" }} />
                <BsStarFill style={{ color: "var(--cta-yellow)" }} />
                <BsStarFill style={{ color: "var(--cta-yellow)" }} />
                <BsStarHalf style={{ color: "var(--cta-yellow)" }} />
              </div>
              <div style={{ color: "white" }}>
                100k Reviews
              </div>
            </div>
            <div style={{ display: "flex", gap: "50px" }}>
              <Link to="/"> <img style={{ height: "80px" }} src={android} alt="" /></Link>

            </div>
          </div>
        </div>
      </div> */}
      {/* <div style={{  backgroundColor: "white", paddingBottom: "50px" }}>
        <div className='container' style={{ flexDirection: "column" }}>
          <h2 style={{ color: "black", marginLeft: "20px", marginTop: "30px", fontSize: "40px" }}>
            Explore Our Astrology Blog
          </h2>
          <p style={{ marginLeft: "20px", color: "gray", fontSize: "18px", marginTop: "5px", marginBottom: "30px" }}>Discover Your Path with our Informative Articles</p>
          <Carousel responsive={responsive2}
            swipeable={true}
            draggable={true}
            infinite={true}
            customTransition="all 1s"
            keyBoardControl={true}
            arrows={true}
            focusOnSelect={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            centerMode={false}
          >
            <Link to="/blog/6560580976a15f16a1af9311" style={{ padding: "10px 60px" }}>
              <div className='blogSlide' style={{ display: "flex", gap: "20px" }}>
                <div style={{ flex: "1", width: "100%", aspectRatio: "16 / 9", backgroundColor: "red", overflow: "hidden" }}>
                  <img src={i} style={{ width: "100%", height: "100%", objectFit: "cover" }} alt="" />
                </div>
                <div style={{ flex: "1", padding: "20px 20px 20px 0px", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "5px" }}>
                  <div className='blogSlideAuthor' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <div style={{ height: "70px", aspectRatio: "1", borderRadius: "50%", backgroundColor: "red", overflow: "hidden" }}>
                        <img src={img} style={{ height: "100%", width: "100%" }} alt="" />
                      </div>
                      <div>
                        <h1 style={{ fontSize: "18px", textTransform: "capitalize" }}>name</h1>
                        <p style={{}}>date</p>

                      </div>
                    </div>
                    <div className='mobView' style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <FaEye />
                      <span>23875</span>
                    </div>
                  </div>

                  <p className='slideBlogHeading' style={{ fontSize: "40px" }}>Discover Your Path with our Informative Articles </p>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et magnam possimus facere, aut repellat laudantium soluta iusto laborum in deserunt itaque perferendis laboriosam, minus aspernatur labore ipsum explicabo atque. Minus.</p>
                  <div className='deskView' style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <FaEye />
                    <span>23875</span>
                  </div>
                </div>
              </div>

            </Link>
          </Carousel>


          <div style={{ display: "flex", gap: "20px", flexWrap: "wrap", marginTop: "40px" }}>
            <div style={{ borderRadius: "10px", overflow: "hidden", flex: "1", minWidth: "250px" }}>
              <div style={{ backgroundColor: "var(--yellow)", width: "100%", aspectRatio: "16 / 9", display: "flex", justifyContent: "center" }}>
                <img src={i} alt="" />
              </div>
              <div style={{ backgroundColor: "var(--bg-yellow)", padding: "10px 30px", height: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <FaEye />
                    <span>23875</span>
                  </div>
                  <div>
                    datte
                  </div>
                </div>
                <h1 style={{ marginTop: "5px" }}>Discover Your Path with our Informative Articles </h1>
                <p style={{ maxHeight: "100px", overflow: "hidden" }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et magnam possimus facere, aut repellat laudantium soluta iusto laborum in deserunt itaque perferendis laboriosam, minus aspernatur labore ipsum explicabo atque. Minus.</p>

              </div>
            </div>
            <div style={{ borderRadius: "10px", overflow: "hidden", flex: "1", minWidth: "250px" }}>
              <div style={{ backgroundColor: "var(--yellow)", width: "100%", aspectRatio: "16 / 9", display: "flex", justifyContent: "center" }}>
                <img src={i} alt="" />
              </div>
              <div style={{ backgroundColor: "var(--bg-yellow)", padding: "10px 30px", height: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <FaEye />
                    <span>23875</span>
                  </div>
                  <div>
                    datte
                  </div>
                </div>
                <h1 style={{ marginTop: "5px" }}>Discover Your Path with our Informative Articles </h1>
                <p style={{ maxHeight: "100px", overflow: "hidden" }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et magnam possimus facere, aut repellat laudantium soluta iusto laborum in deserunt itaque perferendis laboriosam, minus aspernatur labore ipsum explicabo atque. Minus.</p>

              </div>
            </div>
            <div style={{ borderRadius: "10px", overflow: "hidden", flex: "1", minWidth: "250px" }}>
              <div style={{ backgroundColor: "var(--yellow)", width: "100%", aspectRatio: "16 / 9", display: "flex", justifyContent: "center" }}>
                <img src={i} alt="" />
              </div>
              <div style={{ backgroundColor: "var(--bg-yellow)", padding: "10px 30px", height: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <FaEye />
                    <span>23875</span>
                  </div>
                  <div>
                    datte
                  </div>
                </div>
                <h1 style={{ marginTop: "5px" }}>Discover Your Path with our Informative Articles </h1>
                <p style={{ maxHeight: "100px", overflow: "hidden" }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et magnam possimus facere, aut repellat laudantium soluta iusto laborum in deserunt itaque perferendis laboriosam, minus aspernatur labore ipsum explicabo atque. Minus.</p>
              </div>
            </div>
          </div>

          
        </div>
      </div > */}
      <div style={{ overflow: "hidden", background: "var(--bg-white)" }}>
        <div className='container' style={{ display: "flex", flexDirection: "column" }}>
          <h2 className='staticHeading' style={{ color: "var(--bg-dark)", textAlign: "center", marginTop: "30px", marginBottom: "40px", fontSize: "40px" }}>Why you Choose us.</h2>
          <div className='cardhasfj' style={{ display: "flex", gap: "4%" }}>
            <div className='sahdf' style={{ flex: "1", overflow: "hidden" }}>
              <img src='/horoscope.jpeg' alt="" style={{ objectFit: "cover", height: "100%", width: "100%" }} />
            </div>
            <div style={{ flex: "1", padding: "10px 0px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <h2 className='staticHeading' style={{ color: "var(--bg-dark)", marginBottom: "5px", fontSize: "30px" }}> Discover the Magic of Our Daily Horoscope!</h2>
              <p style={{ fontSize: "20px" }}>Exciting news for all astrology enthusiasts!  Unveil the mysteries of your day with the Best Daily Horoscope by UNZZIP TRUTH. Our Psychic Masters have curated insightful predictions to guide you through every twist and turn of life.
              </p>
              <br />
              <p style={{ fontSize: "20px", marginTop: "20px;" }}>Don't miss out on the cosmic wisdom. Join our community of believers and skeptics alike. Trust us for the best in astrological insights!</p>
            </div>
          </div>
          <div className='cardhasfj' style={{ display: "flex", gap: "4%", marginTop: "30px" }}>

            <div style={{ flex: "1", padding: "10px 0px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <h2 className='staticHeading' style={{ color: "var(--bg-dark)", marginBottom: "5px", fontSize: "30px" }}>Unlock the Mysteries with a FREE 1-Card Tarot Reading!</h2>
              <p style={{ fontSize: "20px" }}>Curious about what the cards have in store for you?  We're offering you the chance to experience the magic with a FREE 1-Card Tarot Reading!
              </p>
              <p style={{ fontSize: "20px", margin: "15px 0px" }}>
                Embrace the wisdom of the cards and gain clarity on your journey. Whether it's love, career, or personal growth, let the tarot be your guide!

              </p> <p style={{ fontSize: "20px" }}>
                Limited time offer! Don't miss this opportunity to discover the secrets that await you

              </p>
              {/* <p style={{ fontSize: "20px", fontWeight: "700", margin: "10px 0px" }}>Why you choose us</p> */}
              {/* <ul>
                <li style={{ fontSize: "20px", }}>* Accurate insights tailored to your zodiac sign</li>
                <li style={{ fontSize: "20px", }}>* Expert astrologers decoding the celestial influences</li>
                <li style={{ fontSize: "20px", }}>* Daily doses of inspiration, love, and career guidance</li>
                <li style={{ fontSize: "20px", }}>* It's like having your own personal cosmic roadmap!</li>
              </ul> */}
              {/* <br />
              <p style={{ fontSize: "20px", marginTop: "20px;" }}>Don't miss out on the cosmic wisdom. Join our community of believers and skeptics alike. Trust us for the best in astrological insights!</p> */}

            </div>
            <div style={{ flex: "1", overflow: "hidden" }}>
              <img src='/trato.jpeg' alt="" style={{ objectFit: "cover", height: "100%", width: "100%" }} />
            </div>
          </div>
          <div className='cardhasfj' style={{ display: "flex", gap: "4%", marginTop: "30px" }}>
            <div className='sahdf' style={{ flex: "1", overflow: "hidden" }}>
              <img src='/expert.jpeg' alt="" style={{ objectFit: "cover", height: "100%", width: "100%" }} />
            </div>
            <div style={{ flex: "1", padding: "10px 0px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <h2 className='staticHeading' style={{ color: "var(--bg-dark)", marginBottom: "5px", fontSize: "30px" }}>Meet Our Expert Psychic Master!</h2>
              <p style={{ fontSize: "20px" }}>Curious minds, listen up! Here's why our Psychic Master stands out from the cosmic crowd
              </p>
              <p style={{ fontSize: "20px", margin: "15px 0px" }}>Precision is key! Our Psychic Master is known for accurate predictions, providing you with the foresight you need to navigate life's twists and turns.</p>
              <p style={{ fontSize: "20px", }}>
                Beyond predictions, Our Expert Psychic Master offers guidance that can be truly transformative. Illuminate your path and unlock your full potential.</p>
            </div>
          </div>
          <div className='close800' style={{ display: "flex", gap: "10px", background: "white", width: "800px", borderRadius: "50px", height: "80px", margin: "30px auto", alignItems: "center", overflow: "hidden", }}>
            <p style={{ flex: "1", display: "flex", flexDirection: "column" }}><span style={{ fontSize: "20px", textAlign: "center", fontWeight: "600", textTransform: "capitalize", }}>so why you are waiting?</span>
              <span style={{ textAlign: "center", fontSize: "17px", textTransform: "capitalize" }}>just Go and Sign-Up</span></p>
            <div onClick={() => navigate("/auth?sign-up=true")} style={{ flex: "1", cursor: "pointer", height: "100%", width: "50%", borderRadius: "50px", backgroundColor: "var(--yellow)", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "22px", fontWeight: "500" }}>Sign-Up</div>
          </div>
        </div>
      </div >

      <Testimonial />
      {/* <div style={{  backgroundColor: "white" }}>
        <div className='container' style={{ flexDirection: "column", padding: "70px 20px" }}>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 2fr", columnGap: "20px" }}>
            <div style={{ borderRadius: "10px", overflow: "hidden" }}>
              <div style={{ backgroundColor: "var(--yellow)", display: "flex", justifyContent: "center", padding: "10px" }}>
                <MdOutlinePriceCheck size={70} style={{ marginTop: "15px" }} />
              </div>
              <div style={{ backgroundColor: "var(--bg-yellow)", padding: "80px 30px", minheight: "300px", height: "100%" }}>
                <h2 style={{ textAlign: "center", fontSize: "40px" }}>5rs/min</h2>
                <p style={{ textAlign: "center" }}>Online psychic readings starting at 1rs/min</p>
              </div>
            </div>
            <div style={{ borderRadius: "10px", overflow: "hidden" }}>
              <div style={{ backgroundColor: "var(--yellow)", display: "flex", justifyContent: "center", padding: "10px" }}>
                <AiFillGolden size={70} style={{ marginTop: "15px" }} />
              </div>
              <div style={{ backgroundColor: "var(--bg-yellow)", padding: "80px 30px", minheight: "300px", height: "100%" }}>
                <h2 style={{ textAlign: "center", fontSize: "40px" }}>5 Minutes  Free</h2>
                <p style={{ textAlign: "center" }}>5 Minutes Free off Your first reading</p>
              </div>
            </div>
            <div style={{ borderRadius: "10px", overflow: "hidden" }}>
              <div style={{ backgroundColor: "var(--yellow)", display: "flex", justifyContent: "center", padding: "10px" }}>
                <FaGifts size={70} style={{ marginTop: "15px" }} />
              </div>
              <div style={{ backgroundColor: "var(--bg-yellow)", padding: "80px 30px", minheight: "300px", height: "100%" }}>
                <h2 style={{ textAlign: "center", fontSize: "40px" }}>5 Minutes  Free</h2>
                <p style={{ textAlign: "center" }}>5 Minutes Free off Your first reading</p>
              </div>
            </div>
          </div>
          <br />
          <h2 style={{ textAlign: "center", fontSize: "40px" }}>Why you Choose Us</h2>
          <div style={{ display: "flex", gap: "20px", justifyContent: "space-between", flexWrap: "wrap", marginTop: "30px" }}>
            <div style={{ borderRadius: "10px", overflow: "hidden", flex: "1", backgroundColor: "#ffc8c8", padding: "20px" }}>
              <h2>1</h2>
              <h2>Language Compatibility </h2>
              <p>Get Consltant from your favorate astrologer in your language</p>
            </div>
            <div style={{ borderRadius: "10px", overflow: "hidden", flex: "1", backgroundColor: "#c8ffc9", padding: "20px" }}>
              <h2>2</h2>
              <h2>Low Priceing </h2>
              <p>You can get best astrologets in afordable price .</p>
            </div>
            <div style={{ borderRadius: "10px", overflow: "hidden", flex: "1", backgroundColor: "#c8d8ff", padding: "20px" }}>
              <h2>3</h2>
              <h2>verity Of chooice</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugiat reprehenderit necessitatibus.</p>
            </div>
            <div style={{ borderRadius: "10px", overflow: "hidden", flex: "1", backgroundColor: "#e8c8ff", padding: "20px" }}>
              <h2>4</h2>
              <h2>Language </h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugiat reprehenderit necessitatibus.</p>
            </div>

          </div>
        </div>
      </div>  */}
      <Faq />
      <Services />
      <Footer />
    </>
  )
}


export default Home
